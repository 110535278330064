import {Slider, styled} from "@mui/material";
import React, {useContext} from "react";
import {SettingsContext} from "../../context/settings-context";
import {SoundfontProvider} from "../../../core/soundfont-provider";
import {audioContext, DEFAULT_INSTRUMENT, soundfontHostname} from "../../../model/global-constants";
import IconButton from "@mui/material/IconButton";
import {collectBarsToPlay, getExpectedPlaybackDuration, getNotesToPlay, playNotes} from "../../../utils/playback-utils";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import {BarContext} from "../../context/bar-context";
import {SkeletonData} from "../../../model/skeleton-entities-data/skeleton-data";
import {LoopPlay} from './loop-play';
import {PlaybackContext} from "../../context/playback-context";
import {groupBy} from "../../../utils/js-utils";

export interface PlaybackModuleProps {
    iconColor?: string;
    bars?: SkeletonData[]
}

export const StyledSlider = styled(Slider)(({theme}) => ({
    '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
    }
}));

let intervalId;

export const PlaybackModule = ({iconColor, bars}: PlaybackModuleProps) => {
    const {settings} = useContext(SettingsContext);
    const {activeSheet, activeSubSheet, sheets} = useContext(BarContext);
    const {playbackState, updatePlaybackState} = useContext(PlaybackContext)

    const barsDataToPlay = bars ?
        bars.map((bar, idx) => ({data: bar, relativePosition: idx})) :
        collectBarsToPlay(settings.isMasteringMode, activeSubSheet || activeSheet, sheets)

    const notes = getNotesToPlay(barsDataToPlay);

    const stopPlay = () => {
        clearInterval(intervalId);
        updatePlaybackState({currentActiveNodeId: -1})
    }

    const getTotalNumberOfBarsInPlaybackLine = (barsToPlay) => {
        return Math.max(...barsToPlay.map(item => item.relativePosition))
    }

    const schedulePlay = (playFunction) => {

    }

    return (
        <SoundfontProvider
            instrumentName={DEFAULT_INSTRUMENT}
            audioContext={audioContext}
            hostname={soundfontHostname}
            render={({playNote, stopNote, stopAllNotes}) => (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <div style={{display: "flex", flexDirection: "row"}}>

                        <IconButton
                            onClick={() => {
                                let counter = 0;
                                let currentSkeletonIndex = 1;
                                const expectedBarChangeInterval = getExpectedPlaybackDuration(settings);
                                const maxBarNumbersToPlay = getTotalNumberOfBarsInPlaybackLine(barsDataToPlay)
                                // console.log('Interval every', expectedBarChangeInterval)
                                // console.log('At maximum', maxBarNumbersToPlay)

                                intervalId = setInterval(() => {
                                        if (counter > maxBarNumbersToPlay) {
                                            stopPlay();
                                            return
                                        }
                                        const currentPlayingIndex = playbackState.currentActiveNodeId;
                                        console.log("currentPlayingIndex",currentPlayingIndex)
                                        console.log("counter",counter)
                                        console.log("currentSkeletonIndex",currentSkeletonIndex)
                                        updatePlaybackState({currentActiveNodeId:currentSkeletonIndex})
                                    currentSkeletonIndex++
                                    counter++
                                    }, expectedBarChangeInterval
                                )

                                updatePlaybackState({currentActiveNodeId:0})
                                //give just a bit of time to render
                                // setTimeout(() => {
                                    playNotes(notes, playNote, settings.playbackTempo, settings.alterGainForFeather, settings.barSize);
                                // },0)


                            }}
                            size="large">
                            <PlayArrowRoundedIcon fontSize="large" style={{fill: iconColor ? iconColor : "#176503"}}/>
                        </IconButton>
                        <LoopPlay notes={notes} playNote={playNote} settings={settings} stop={() => {
                            stopNote();
                            stopAllNotes();
                            stopPlay();
                        }}/>
                        <IconButton
                            onClick={() => {
                                stopNote();
                                stopAllNotes();
                                stopPlay();
                            }}
                            size="large">
                            <StopRoundedIcon fontSize="large" style={{fill: iconColor ? iconColor : "#ac0707"}}/>
                        </IconButton>


                    </div>
                </div>

            )}
        />
    );
}
