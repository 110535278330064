import {INote} from "../skeleton-entities-data/note-data";
import {LevelTreeNode} from "./level-tree-node";
import {HandType} from "../skeleton-entities-data/skeleton-data";

export class OctaveLevelInfo {

    private nodes: Array<LevelTreeNode>;

    constructor(private handType: HandType) {
        const [minMidi, maxMidi] = this.getMidiRangeForHandType(handType);
        this.nodes = [new LevelTreeNode({min: minMidi, max: maxMidi, midiNumbers:[]})]
    }

    get ranges(){
        return this.nodes;
    }

    public insert(note: INote, shouldBeSplitting = false) {
        const nodeToInsert = this.getNodeForNote(note)
        nodeToInsert.insertNote(note);

        if (shouldBeSplitting) {
            const newNodes = nodeToInsert.splitBy(note);
            this.nodes.splice(this.nodes.indexOf(nodeToInsert), 1)
            this.nodes.push(...newNodes)
        }
    }

    public getNodeForNote(note: INote) {
        const maybeNodesToInsert = this.nodes.filter(node => node.containsNote(note));
        // console.log("noteToInsert", note)
        if (maybeNodesToInsert.length < 1) {
            throw new Error("Could not find proper midi interval to insert note " + note)
        }
        return maybeNodesToInsert[0]
    }

    private getMidiRangeForHandType(hand: HandType) {
        return  hand === HandType.LEFT ? [20,72]:[48,108]
    }

}
