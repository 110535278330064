export const DDTScheme = {
    "settings": {
        "quadratSize": 8,
        "octaveNotation": {
            "name": "Именная",
            "entries": [
                ".sk",
                ".k",
                ".b",
                ".m",
                ".1",
                ".2",
                ".3",
                ".4"
            ],
            "defaultOctaveLeft": ".m",
            "defaultOctaveRight": ".1",
            "regexpKeys": ".skbm1-5",
            "regexp": {}
        }
    },
    "data": [
        [
            "Куплет-1",
            {
                "name": "Куплет-1",
                "index": 0,
                "bars": [
                    {
                        "id": "8ea8d0cd-8fd5-444c-8d5a-1e371e3d88a9",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f6322710-0f84-4c92-a017-52817f5f2657",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9149bf15-5fd4-41a4-96c3-c039df7ef060",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9304829b-2b24-44a9-8399-236d89686f3f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3e16c78-9b02-4219-89d9-23588008496a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cdf1dd51-4e18-41c0-8354-943f552b00e6",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a959a52f-c6fc-4b80-a3e4-a6b3fb4d3bd8",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5aa1d6d5-249f-4245-85ef-e2aa8a976a22",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b28639c0-e45d-4cdf-82e3-ef2086e8f222",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2dc28bd2-4201-48cb-861e-5a6b9bc87abf",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d409a220-5a52-4ea9-8397-ee468ccfd1c8",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e711e4a0-7c32-49a9-ad10-e1767075fb69",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ceac578-17c0-46a9-967c-9609ad057c2d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "259e5356-e635-4e05-9c80-3b582068593f",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b9661872-f1e2-4a2d-b177-5eb865f9caa3",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "9fd97919-b2d9-41d6-9650-5a04d23ad7e9",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4a7d7b27-67c2-4cc5-b176-b2a1e9c35bf5",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb2951d4-c0ae-4bc8-9060-f7a7f15e2963",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a3d57c27-5e8d-438a-a294-77af8f7bd422",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c032dec5-be41-4f60-99a9-1dc0734d36a1",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb g bb:eb g bb:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c5279a17-5d85-433f-a80b-9faab5dce313",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "be1029bb-fd89-4363-8663-22dec371fc88",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "de8bb28a-27ed-4275-801d-e5b8cb92d518",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "2f896159-9f27-4deb-96a8-e2490b05c72b",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0d8619be-a8dd-47e0-969e-46cc28b2c017",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "2629ccd7-2baf-4794-9018-7c2cb366eadf",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "76774474-27a1-4437-95fa-c045ae92e0a3",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c81dc7c1-f055-411e-9f18-924b7cc075b3",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd0ee573-18ff-4720-a896-7f7f20bac1b0",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e07e60b-f844-460a-ba83-d422b6af7273",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9196d7c6-00e6-434c-9b07-76cd86b2f81a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aecb4c42-aae5-42bc-8c18-eebc674ef3f4",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "da233399-6032-4399-b6cd-7bc688a97384",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "9f902751-27d7-4ea1-8767-8c908176cf03",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "923338cf-82e8-4f11-b0a5-4bf442e40486",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ad62ca14-a60a-43ad-949e-114e9f1ad469",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d211238e-2020-4e7b-800a-72c586442b1a",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "6aac74d9-f82a-40fe-a926-95fbe1b6ee84",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b5769451-bd5e-4845-b5c7-4f35e9069a66",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b7c341c9-7e27-46a2-824d-07f64fee448b",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fdd8e0f3-eb5a-41dd-87d5-fef53c6fb1bb",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "56aff30a-0392-4eb3-90d3-f4f8ba2663b4",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "aad4037f-0cf2-4444-b1b8-3e961f1d051a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bfea33b-be93-4aff-a3eb-61b4d500a994",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4988c71a-08da-4490-8bc2-1f5fd40b4b57",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9a846c4-a81b-4602-b82c-4d8ea82bdd8c",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "25931c0b-661a-4558-9257-9a6570a1b8a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "22737928-bc32-4cc2-a666-9389f125a3f4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a1a267d4-4458-4157-96d8-4b4f92e56636",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e6c14f1-c8df-4028-99f2-5c8edb9d55df",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "2e2d8ca4-6b5d-4700-93fa-9efe76f14996",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5b488d38-d6fb-4cfc-8883-fc99c47380a1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3df46cc0-b86f-4978-8afc-1143f430734e",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5d04f2b2-01ab-43e7-bbe3-082e4b18993f",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "afee776b-ebcf-4c68-82f9-fef3a3ff5186",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3 a3 d:f#3 a3 d:c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1e4c6637-ce92-443a-89de-635f9b24c621",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b64c54e4-2066-4390-a6b4-6d6187746ec0",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "24154c4e-ade6-489b-abd2-91e4c1f33364",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4bc18ff8-643e-4681-900a-caea61c3aa34",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c1aaf13a-d9f9-486a-9933-db307614ea83",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4ee4a6b5-38a2-4e2f-a7fe-798072d8ab7d",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "cafa72c8-557f-4d45-a4b2-f64c9bf1f7f9",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8cf3993c-45a3-428d-9ead-a0f3cf8e164a",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ba1c8cc5-ce7b-43bb-906e-60fc768a8c09",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f3587e0a-df75-4df2-b54f-8ec673e86992",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "de04a611-dadb-4d9d-a3c1-092a50544ba7",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f80cd75-3052-4116-b02d-26c49b325592",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b3b71e93-597e-4737-8832-0c85b391817a",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "5c138a50-41cd-438e-a3a3-2e0c6d550ff9",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f6322710-0f84-4c92-a017-52817f5f2657",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9149bf15-5fd4-41a4-96c3-c039df7ef060",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9304829b-2b24-44a9-8399-236d89686f3f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3e16c78-9b02-4219-89d9-23588008496a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cdf1dd51-4e18-41c0-8354-943f552b00e6",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a959a52f-c6fc-4b80-a3e4-a6b3fb4d3bd8",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5aa1d6d5-249f-4245-85ef-e2aa8a976a22",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b28639c0-e45d-4cdf-82e3-ef2086e8f222",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2dc28bd2-4201-48cb-861e-5a6b9bc87abf",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d409a220-5a52-4ea9-8397-ee468ccfd1c8",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e711e4a0-7c32-49a9-ad10-e1767075fb69",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ceac578-17c0-46a9-967c-9609ad057c2d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "259e5356-e635-4e05-9c80-3b582068593f",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b9661872-f1e2-4a2d-b177-5eb865f9caa3",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "fc2725e9-ff35-4c32-91fd-ce335b858b00",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4a7d7b27-67c2-4cc5-b176-b2a1e9c35bf5",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb2951d4-c0ae-4bc8-9060-f7a7f15e2963",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a3d57c27-5e8d-438a-a294-77af8f7bd422",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c032dec5-be41-4f60-99a9-1dc0734d36a1",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb g bb:eb g bb:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "349aa4fa-6431-407e-b7d9-5143f3a9c138",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "14666597-ffec-4e7a-a87f-27634e2bdfd9",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "727fa5ae-8b97-40df-b983-8aa98b2619f7",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b569f8f1-8b9f-4c1b-ba72-56d951dcfdc5",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "752db079-2017-4ba6-927b-39fc4cc3d1df",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0dd74afb-2fe0-4a8e-9e36-2cfc00736e50",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f73d7522-06a6-4019-99cc-221323153c10",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c81dc7c1-f055-411e-9f18-924b7cc075b3",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd0ee573-18ff-4720-a896-7f7f20bac1b0",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e07e60b-f844-460a-ba83-d422b6af7273",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9196d7c6-00e6-434c-9b07-76cd86b2f81a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aecb4c42-aae5-42bc-8c18-eebc674ef3f4",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "da233399-6032-4399-b6cd-7bc688a97384",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "c048e9c1-7336-4e36-9ffb-e414990332d2",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "923338cf-82e8-4f11-b0a5-4bf442e40486",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ad62ca14-a60a-43ad-949e-114e9f1ad469",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d211238e-2020-4e7b-800a-72c586442b1a",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "6aac74d9-f82a-40fe-a926-95fbe1b6ee84",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b5769451-bd5e-4845-b5c7-4f35e9069a66",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b7c341c9-7e27-46a2-824d-07f64fee448b",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fdd8e0f3-eb5a-41dd-87d5-fef53c6fb1bb",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "56aff30a-0392-4eb3-90d3-f4f8ba2663b4",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "aad4037f-0cf2-4444-b1b8-3e961f1d051a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bfea33b-be93-4aff-a3eb-61b4d500a994",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4988c71a-08da-4490-8bc2-1f5fd40b4b57",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9a846c4-a81b-4602-b82c-4d8ea82bdd8c",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "25931c0b-661a-4558-9257-9a6570a1b8a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "22737928-bc32-4cc2-a666-9389f125a3f4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a1a267d4-4458-4157-96d8-4b4f92e56636",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e6c14f1-c8df-4028-99f2-5c8edb9d55df",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "1ce8fd60-90ba-4d8c-b722-c3a9e8d94026",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5a390ee4-18df-4dd4-b02a-a2d0d825494b",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7ec6a946-ab20-49fe-a0f0-36dce1dcbb3b",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "22226cab-3a89-4967-b43d-afbe42dd5b2e",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3315145-14ab-4694-a99f-4d6a351c4661",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "d:eb:f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ddb791e0-2f0d-4dff-9d46-fc29fb541818",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "038a176d-6c9d-4701-bba8-9083edb8f12a",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ca1cac8f-5c06-4862-b674-0ae3dff84c41",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2d2fa089-47f1-4efb-95c2-c1adb492b96d",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b89659eb-134f-4eff-bfe3-1ba1bb58fe43",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c2610819-7a9b-4cc8-9660-366007328314",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c78dcc0c-d4d9-4524-a9cd-c300ad5dcb37",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e9fe9bb0-86dd-4166-a272-bd5efc54ceb6",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fe681eca-7027-488d-9dec-4f51bb15a36a",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    }
                ]
            }
        ],
        [
            "Куплет-1-1",
            {
                "name": "Часть 1",
                "parentName": "Куплет-1",
                "index": 0,
                "bars": [
                    {
                        "id": "8ea8d0cd-8fd5-444c-8d5a-1e371e3d88a9",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f6322710-0f84-4c92-a017-52817f5f2657",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9149bf15-5fd4-41a4-96c3-c039df7ef060",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9304829b-2b24-44a9-8399-236d89686f3f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3e16c78-9b02-4219-89d9-23588008496a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cdf1dd51-4e18-41c0-8354-943f552b00e6",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a959a52f-c6fc-4b80-a3e4-a6b3fb4d3bd8",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5aa1d6d5-249f-4245-85ef-e2aa8a976a22",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b28639c0-e45d-4cdf-82e3-ef2086e8f222",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2dc28bd2-4201-48cb-861e-5a6b9bc87abf",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d409a220-5a52-4ea9-8397-ee468ccfd1c8",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e711e4a0-7c32-49a9-ad10-e1767075fb69",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ceac578-17c0-46a9-967c-9609ad057c2d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "259e5356-e635-4e05-9c80-3b582068593f",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b9661872-f1e2-4a2d-b177-5eb865f9caa3",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "9fd97919-b2d9-41d6-9650-5a04d23ad7e9",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4a7d7b27-67c2-4cc5-b176-b2a1e9c35bf5",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb2951d4-c0ae-4bc8-9060-f7a7f15e2963",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a3d57c27-5e8d-438a-a294-77af8f7bd422",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c032dec5-be41-4f60-99a9-1dc0734d36a1",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb g bb:eb g bb:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c5279a17-5d85-433f-a80b-9faab5dce313",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "be1029bb-fd89-4363-8663-22dec371fc88",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "de8bb28a-27ed-4275-801d-e5b8cb92d518",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "2f896159-9f27-4deb-96a8-e2490b05c72b",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0d8619be-a8dd-47e0-969e-46cc28b2c017",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "2629ccd7-2baf-4794-9018-7c2cb366eadf",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "76774474-27a1-4437-95fa-c045ae92e0a3",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c81dc7c1-f055-411e-9f18-924b7cc075b3",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd0ee573-18ff-4720-a896-7f7f20bac1b0",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e07e60b-f844-460a-ba83-d422b6af7273",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9196d7c6-00e6-434c-9b07-76cd86b2f81a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aecb4c42-aae5-42bc-8c18-eebc674ef3f4",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "da233399-6032-4399-b6cd-7bc688a97384",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "9f902751-27d7-4ea1-8767-8c908176cf03",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "923338cf-82e8-4f11-b0a5-4bf442e40486",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ad62ca14-a60a-43ad-949e-114e9f1ad469",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d211238e-2020-4e7b-800a-72c586442b1a",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "6aac74d9-f82a-40fe-a926-95fbe1b6ee84",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b5769451-bd5e-4845-b5c7-4f35e9069a66",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b7c341c9-7e27-46a2-824d-07f64fee448b",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fdd8e0f3-eb5a-41dd-87d5-fef53c6fb1bb",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "56aff30a-0392-4eb3-90d3-f4f8ba2663b4",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "aad4037f-0cf2-4444-b1b8-3e961f1d051a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bfea33b-be93-4aff-a3eb-61b4d500a994",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4988c71a-08da-4490-8bc2-1f5fd40b4b57",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9a846c4-a81b-4602-b82c-4d8ea82bdd8c",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "25931c0b-661a-4558-9257-9a6570a1b8a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "22737928-bc32-4cc2-a666-9389f125a3f4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a1a267d4-4458-4157-96d8-4b4f92e56636",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e6c14f1-c8df-4028-99f2-5c8edb9d55df",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "2e2d8ca4-6b5d-4700-93fa-9efe76f14996",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5b488d38-d6fb-4cfc-8883-fc99c47380a1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3df46cc0-b86f-4978-8afc-1143f430734e",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5d04f2b2-01ab-43e7-bbe3-082e4b18993f",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "afee776b-ebcf-4c68-82f9-fef3a3ff5186",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3 a3 d:f#3 a3 d:c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1e4c6637-ce92-443a-89de-635f9b24c621",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b64c54e4-2066-4390-a6b4-6d6187746ec0",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "24154c4e-ade6-489b-abd2-91e4c1f33364",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4bc18ff8-643e-4681-900a-caea61c3aa34",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c1aaf13a-d9f9-486a-9933-db307614ea83",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4ee4a6b5-38a2-4e2f-a7fe-798072d8ab7d",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "cafa72c8-557f-4d45-a4b2-f64c9bf1f7f9",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8cf3993c-45a3-428d-9ead-a0f3cf8e164a",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ba1c8cc5-ce7b-43bb-906e-60fc768a8c09",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f3587e0a-df75-4df2-b54f-8ec673e86992",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "de04a611-dadb-4d9d-a3c1-092a50544ba7",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f80cd75-3052-4116-b02d-26c49b325592",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b3b71e93-597e-4737-8832-0c85b391817a",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "5c138a50-41cd-438e-a3a3-2e0c6d550ff9",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f6322710-0f84-4c92-a017-52817f5f2657",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9149bf15-5fd4-41a4-96c3-c039df7ef060",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9304829b-2b24-44a9-8399-236d89686f3f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3e16c78-9b02-4219-89d9-23588008496a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cdf1dd51-4e18-41c0-8354-943f552b00e6",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a959a52f-c6fc-4b80-a3e4-a6b3fb4d3bd8",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5aa1d6d5-249f-4245-85ef-e2aa8a976a22",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b28639c0-e45d-4cdf-82e3-ef2086e8f222",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2dc28bd2-4201-48cb-861e-5a6b9bc87abf",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d409a220-5a52-4ea9-8397-ee468ccfd1c8",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e711e4a0-7c32-49a9-ad10-e1767075fb69",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ceac578-17c0-46a9-967c-9609ad057c2d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "259e5356-e635-4e05-9c80-3b582068593f",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b9661872-f1e2-4a2d-b177-5eb865f9caa3",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "fc2725e9-ff35-4c32-91fd-ce335b858b00",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4a7d7b27-67c2-4cc5-b176-b2a1e9c35bf5",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb2951d4-c0ae-4bc8-9060-f7a7f15e2963",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a3d57c27-5e8d-438a-a294-77af8f7bd422",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c032dec5-be41-4f60-99a9-1dc0734d36a1",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb g bb:eb g bb:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "349aa4fa-6431-407e-b7d9-5143f3a9c138",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "14666597-ffec-4e7a-a87f-27634e2bdfd9",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "727fa5ae-8b97-40df-b983-8aa98b2619f7",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b569f8f1-8b9f-4c1b-ba72-56d951dcfdc5",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "752db079-2017-4ba6-927b-39fc4cc3d1df",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0dd74afb-2fe0-4a8e-9e36-2cfc00736e50",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f73d7522-06a6-4019-99cc-221323153c10",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c81dc7c1-f055-411e-9f18-924b7cc075b3",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd0ee573-18ff-4720-a896-7f7f20bac1b0",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e07e60b-f844-460a-ba83-d422b6af7273",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9196d7c6-00e6-434c-9b07-76cd86b2f81a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aecb4c42-aae5-42bc-8c18-eebc674ef3f4",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "da233399-6032-4399-b6cd-7bc688a97384",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "c048e9c1-7336-4e36-9ffb-e414990332d2",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "923338cf-82e8-4f11-b0a5-4bf442e40486",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ad62ca14-a60a-43ad-949e-114e9f1ad469",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d211238e-2020-4e7b-800a-72c586442b1a",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "6aac74d9-f82a-40fe-a926-95fbe1b6ee84",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b5769451-bd5e-4845-b5c7-4f35e9069a66",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b7c341c9-7e27-46a2-824d-07f64fee448b",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fdd8e0f3-eb5a-41dd-87d5-fef53c6fb1bb",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "56aff30a-0392-4eb3-90d3-f4f8ba2663b4",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "aad4037f-0cf2-4444-b1b8-3e961f1d051a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bfea33b-be93-4aff-a3eb-61b4d500a994",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4988c71a-08da-4490-8bc2-1f5fd40b4b57",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9a846c4-a81b-4602-b82c-4d8ea82bdd8c",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "25931c0b-661a-4558-9257-9a6570a1b8a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "22737928-bc32-4cc2-a666-9389f125a3f4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a1a267d4-4458-4157-96d8-4b4f92e56636",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e6c14f1-c8df-4028-99f2-5c8edb9d55df",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "1ce8fd60-90ba-4d8c-b722-c3a9e8d94026",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5a390ee4-18df-4dd4-b02a-a2d0d825494b",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7ec6a946-ab20-49fe-a0f0-36dce1dcbb3b",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "22226cab-3a89-4967-b43d-afbe42dd5b2e",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c3315145-14ab-4694-a99f-4d6a351c4661",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "d:eb:f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ddb791e0-2f0d-4dff-9d46-fc29fb541818",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "038a176d-6c9d-4701-bba8-9083edb8f12a",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ca1cac8f-5c06-4862-b674-0ae3dff84c41",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2d2fa089-47f1-4efb-95c2-c1adb492b96d",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b89659eb-134f-4eff-bfe3-1ba1bb58fe43",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c2610819-7a9b-4cc8-9660-366007328314",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c78dcc0c-d4d9-4524-a9cd-c300ad5dcb37",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e9fe9bb0-86dd-4166-a272-bd5efc54ceb6",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fe681eca-7027-488d-9dec-4f51bb15a36a",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    }
                ]
            }
        ],
        [
            "Куплет 1-2",
            {
                "name": "Часть 1",
                "parentName": "Куплет-1",
                "index": 0,
                "bars": [
                    {
                        "id": "bf601e55-5606-4b57-a460-c5f1b4bcdeea",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0117401e-a8c3-4d01-a8c3-3909d0ef0317",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9ff45768-99dd-4e87-b633-0fffc9df0850",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e515dbb8-bf8c-4fb0-9d76-077dc4b784a1",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9c43e8fb-49c6-468c-9ebf-3792a2ca90ef",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6df9c49e-1744-4e58-ac35-721303b528f8",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b19a275c-78ec-4dd1-b152-68fdcdb3316a",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "db805623-913a-422c-ad04-9e68990ad602",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "caee925a-e73c-43b5-af10-c2e2b2a97a64",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "996fba4f-5eec-457d-b7ae-be040f786dcc",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1f7f2bb2-d3e3-4825-b1af-0a120f353757",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b1e687f6-e60e-45c9-acc7-929c3deb04f5",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "23902c21-8d13-4ba7-b45d-303b4ef0c130",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0d0edb7a-81df-4a92-95cf-a303d048ee6f",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d5b585ff-4073-4022-a3a9-3e26b83aa0eb",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "a66c01fb-17a4-4ac2-a64d-a31e2185dd25",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "644d8089-7a2b-4f9d-8698-afd82d9b54d9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fc20679f-23dd-428c-abb9-d5ec6e109b79",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb02c2c2-06f9-4db1-bfce-6d36e50643af",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "80134d4d-883c-4e01-bfba-9207c52bfc65",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3:c:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e8713b8f-6b8f-4829-967f-c41e9482bd69",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e47c1849-a2c8-4729-a0c1-0d222a20519e",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b747b154-b84c-4a18-8b2d-225d6ff3087d",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "761c2955-9914-4217-8f62-dbc9892f7b99",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0299e43e-7711-420a-bc04-d994d7dca0db",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a769b388-f570-453b-9ccf-4128bc462904",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "10b9b204-e44e-4dca-80bb-8e8794cb3504",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7a2828fc-733e-4a6d-a7ba-283718860a6c",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "06875a84-a536-4882-9f70-3fb21df72aa1",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "46207510-17f4-4f80-b8b5-bb1ee4057d74",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "eb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "75f57e3d-642b-4fbf-a501-deb12133d2d2",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4155ae15-fcbf-4313-82ac-a281d046a964",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d497f90b-577b-4249-ac9d-b2b2bcf6ab50",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6888ff60-86d3-4b82-8f77-b30a506742e7",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b7339e74-2b0b-4ae6-b490-3a691ddd0c5c",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1ef6680b-8225-4e24-b8a1-3c93ffa64da7",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d728583a-741c-4c49-af1f-c75e5b33a230",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f74396fa-0621-4425-a5e2-378eb1887c18",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "190f6ff7-7f64-4724-a05e-5b87c8d9b916",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bc677779-253e-4ac0-9984-54c29e7a7bc0",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "32284a80-1ae4-4383-ba8f-7b494ba20db3",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "000ea663-4231-4ead-89a5-5c0760deed59",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b2400439-41d7-4f3f-91bf-6e5033948ea4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4a7912dd-06f1-49b3-8c0e-b3d56e4db3e4",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "2c11209b-38d2-49dc-92ae-e94dad42c4ee",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b3f591f2-3f86-40a7-a16c-c8e19fdfe0fb",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0a46cb8b-f9fe-4a8e-ae5c-2e9ef0ef776b",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2e586272-788f-4002-b682-3f1cb01a312f",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "d:d:c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4987c247-a3fe-4d3c-b0a4-30923ad452d0",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d52d2195-bac8-4025-97b9-a17778066027",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0469aff8-63ce-40f2-bde8-6484f0d17679",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "216b4457-c4b4-40f1-bc75-ae7526f06b28",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0dececce-c06a-4479-a8f7-a2185a5267eb",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d2d2c970-2825-48e4-82a8-45f2d16b903a",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ef812d1a-e93c-4308-ba31-162bb5a12d6a",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e6afbd9-83c6-4566-996b-421928b19f55",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a8619558-56b8-4c77-aa4a-09d29068651e",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "4d679848-1676-4342-b06e-c58bfef42c69",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "824f3f72-bcf0-4f1f-9976-5595530e5793",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3590b78b-6bed-4b8b-bb28-002b6479b157",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ed8123e7-6804-4320-b1a8-07635024112e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d97f8ed9-d998-4d28-9bea-94ba8f2f391a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e120189b-a535-49c9-a150-5050a6eb7af2",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1d19bb9d-3c32-4ae5-8a06-28ba5aae5ee7",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "864c44db-28b4-426b-a408-b7ebad0c21e0",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7f5244b8-ba0e-4b14-b539-274f28bc9312",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a68e2687-a500-4e8f-add6-bb853a799cf7",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "570b5d0b-d41d-474a-af6c-9eeebcf022b9",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "476a780c-3792-4850-8c5a-1f42a5e0d3fe",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "104f5bce-bdcd-4036-8f6d-29e75bcdd160",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f931f05f-9c03-43ca-9185-0f8b2560c17d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a182829e-b4a6-40ca-a381-ac377caafa84",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "0e328d9b-15bf-4af6-94da-d19d5dc4b01e",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7cea546f-b327-488a-a47c-b96c9c0cabe1",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d65c10a3-aad9-462b-899c-5e8792f63c99",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3fed31c2-f9d4-4118-925c-6b22b86197ed",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "73ca250d-8207-479e-ab65-97997e154382",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb3 eb:bb3 eb:d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f8192590-168f-4321-b1a8-a7456d10f1a4",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5ee65495-5a67-48de-9fb0-df82d4cf03ac",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c6446e2b-93d3-4874-bfbf-5d4b8d72412b",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a13d899f-b521-433c-bb03-4065e8d94ca4",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5fd5143c-9704-465d-a743-6ab2f7f780f7",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "75fdb646-69ae-4f37-b289-b179d4fd4ce2",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "679266af-cd9a-48a4-9ef4-a6737d21fdb1",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "18d42da2-2a07-4064-a362-38dbf8cbd647",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "671c9f08-db78-4500-96dd-173f79aa75aa",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "25fd0cf9-7430-4f30-b468-64cf19c8bdd6",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d69363a9-e5d0-495e-8555-69b17cdcacb5",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "844a1830-8622-4a0a-af16-f51160cfc452",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4cab82d4-714e-4151-b3e3-06de55323424",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4aa3b171-47e8-4014-bdcb-4c4053b8237e",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5f665d14-9f35-4c3d-aafa-fa1601977780",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "2785d8d2-8a87-46f5-8eee-29fec77c0bd1",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "58fe1e42-ec84-4d8c-b5a4-ef20e808c3dc",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ef454482-38d7-4917-8d40-b0fd64e17e70",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "88fc8c98-8998-4726-ad3d-30fc99fc2f71",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c4 g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2c0fa6ab-bce5-40cd-9924-415b6a154b5f",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "76eaab89-b594-4f3a-af73-af6a3f0dc3a8",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4bf2d297-0389-4927-94bb-7e1c5dff29c6",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ce52488c-ad21-47ac-8d2f-eea6c01b78b6",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ff8979e2-3e35-4f8b-bf31-734da0affec2",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "86b88964-df1a-42ce-9063-159d3c68d920",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "69961678-d20e-47d1-9901-4aaaf3989296",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a780a712-25d5-49a7-a661-b83025ea1d6e",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "468921f6-9085-4824-9fef-87e0de72807a",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    },
                    {
                        "id": "20ba0564-6cd4-4339-afee-12e506550f91",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4
                            },
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d4 a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2d9fa9df-b978-4bf4-aaa3-281f622b4591",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "b45d1685-0a25-466e-864b-80c49383c8af",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ebdad9f4-f1f9-4077-8730-21230c6ce0c7",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "d4 a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "eb2df795-9821-4ef7-aa73-a508072582da",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "e8419011-b547-405c-995e-18f2547851dc",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6f9c23bb-1086-4aed-8179-e627d44a234d",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "f#3 a3 d:f#3 a3 d:c c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ebe43180-1cbc-4fe3-9df6-a2608ef2dd23",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0f99080e-d75a-446c-9eef-d0137c000841",
                                        "note": "f#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ba7bc10a-02af-4ab2-a8c0-b2486acf29c3",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "488fb758-de26-4670-9189-cae13dade3f2",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e3fe5fb0-7d6e-427c-aa1c-ddc71d5797f9",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "88224890-f2fc-4ec2-b490-725685c021d1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e3ca913b-f141-4374-aebc-0bd4a7ef0f75",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "321c60c9-2ba7-4377-97bf-029302099d51",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a1fbdf08-5f12-4411-be5d-61d2f8b2c2b0",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "50fa19f8-2332-4af6-9c0d-1296c2a40af8",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "cba6131d-b500-466d-9e3a-35621c2df53d",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2a75bd44-39e2-4cb8-9d37-73b1c3603856",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "97a5d388-4424-4802-bd07-bc164ea5f63e",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "eeb4608a-3cef-46e5-9e1f-4153fa740fbe",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 8
                    }
                ]
            }
        ]
    ]
}
