export const NymphScheme = {
    "settings": {
        "quadratSize": 6,
        "octaveNotation": {
            "name": "Научная",
            "entries": [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7"
            ],
            "defaultOctaveLeft": "3",
            "defaultOctaveRight": "4",
            "regexpKeys": "0-9",
            "regexp": {}
        }
    },
    "data": [
        [
            "Проигрыш-1",
            {
                "name": "Часть 1",
                "index": 0,
                "bars": [
                    {
                        "id": "c020cf0b-ca17-4291-9969-ebde094c3092",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5f6335a9-f2dd-4be6-aa39-0f3cc154178c",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d2a0b8a0-b116-478c-86b3-73c5c6e326ff",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dfd5c3a8-a32d-4503-8a85-75d04f4d6605",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f547aa39-2c67-4382-972a-03095fa3ca5b",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "617d6e00-a575-450a-b727-06d61a4e130a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ba32fdf9-9f6d-420d-897f-3306ac01825e",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fcd2651f-bcb8-408e-831d-c9f0ef16bedf",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d662e28d-ac68-4f5c-9fb0-412d4cf6ae68",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c3b0a93b-b4b1-4b07-97ed-cb2be55b9b1f",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f683a67f-2983-470b-9145-870a55521ca9",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8b6bda1d-5849-4c15-8cc7-95d7acc238e9",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6cd21896-d0f6-4872-89cb-195f25ddd145",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "22733940-ae2a-4e4c-8a8b-54e2a8080327",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "36144850-1070-453a-b8ee-770f8c94ecf4",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "997908bb-7fec-444a-a4fb-849607f852a8",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c51b8e66-9f26-45e3-b04c-19c49b9cb076",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a8e50234-f10e-47f9-a15c-fdcc53e58fde",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7b5d90aa-649f-4868-bd42-0ce7ea165973",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "64df7fb3-6bda-4354-be6f-50bc54bd9d23",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6ae68536-f43e-4f03-b9b1-3f5b0bfc2db9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "05d3c799-878a-440c-8e52-df4d962dfac7",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dd3d9188-60e9-4aaf-9879-3bc6b8ba0208",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "21c8300c-3f49-44e3-9ef2-0dc88c84ceb7",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "abe5208c-9091-4590-ba42-391ba1bfeb18",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6e90f1f9-1446-4ce1-a944-bdae19dcbb69",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ef0d1e34-62a6-4837-b289-69cf3d152962",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3e8e8305-8fc4-4301-bacf-ec08e6542fe8",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9b856eee-c3d7-42e7-8e40-7b120934b6b5",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1bd4febf-b2ff-4463-9719-dfc2ca3436c5",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "12220e9b-aad7-4413-b423-0cbbf5a25dcc",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "21e574f3-8b65-41c6-a958-66199fbc4005",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "6d4b1234-c517-4d5a-ae40-7d28cb146d55",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "42b32bc5-666d-45c5-9a58-f2a7c57771c6",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0cbdb124-37e7-4cbd-8ee6-9312c44cd29b",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "128ff81c-2461-45dd-abac-c902cbbf5133",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1b965581-8c6c-4f8a-bbfc-212a5d24da63",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "72878a87-2ec9-44dd-b89c-8a62fa08b7fd",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "77909eba-27ee-4754-85ca-c81427b9acbb",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "08a64cc7-cda3-4d20-ad59-da47ce500773",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "34a37c01-389c-4adf-9b3d-7663943a8a1f",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ff49263c-830c-4ce8-8eae-87f6ba400a36",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3834ebd5-e610-4e18-9094-605b07acfcef",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1ffc49a2-4c46-4cdf-a92a-dc90e8d08290",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5113e0d0-618c-49cd-80e8-90eacc7ed591",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "f35308e2-9198-4980-872a-516d4cf298a1",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cc2122a3-a245-4479-8551-906cc8c59c47",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "01681a9c-dea1-44a4-bf04-e7a56dbe9f60",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cdd23694-eb11-4d21-8e50-a9ddb470d891",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5bec3396-1a07-4ea1-b52d-012bb8102bcc",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a79fa1f6-18b4-4767-8db0-d03ce3090301",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f63bed69-da10-4e96-bba2-4556497b76d3",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fbd25368-3001-484f-9c33-7a5c8991971b",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "05cd7c90-9617-4223-8a5b-476d0005feb0",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "855df1b2-61e8-4e4c-97cd-41d971f7615d",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b1671642-b66f-4180-bedd-124017454068",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d50ce948-4f05-41bd-9251-819d13f18666",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1a084c53-a9fc-4de8-b9b8-bdcfdc80d3e2",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d8a88724-3b54-4ce8-bda7-79f5204a93b5",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6111e43a-664d-4c26-9fe2-a3bc31f712cf",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "69f53e0b-0b01-4bf0-a1ca-34264ad3b99e",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "301d5486-f4cd-4afd-9709-3b2f06438c32",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e0897416-5a1d-4f3d-bb4b-8e8623b50870",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "08499b27-bc30-49dc-86fb-705e2555b8d1",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "950928b1-52e5-4eb8-b846-0d942d766937",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bdc2ed0-dab6-4ea3-839f-ec1364e3bc4d",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "21d32167-1e13-4b57-98d8-82dc221d3e42",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "eb30a139-c5a4-49d7-9729-ae179ab4c0fb",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a842cd6c-5528-4580-9273-d371a0437412",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c5ba2f74-46ed-497d-93d2-5308cd4ba5d4",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ff49536-112d-4556-8a43-314c10c8d125",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0414dd42-41a9-434c-aa76-c83f704f57a0",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "18aed9f1-81d3-4d8b-a025-eb069def77b5",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "d4b8c8b8-bc01-4fba-a8f9-e48fc26b221f",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4516be81-1a4c-4d00-aeda-2dfa82334fc8",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cebffd7e-c42d-49b2-86c5-98c789ead48c",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "725a6d04-a8d0-4eb4-9244-4cbd3676c183",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1875c929-8987-46b1-abcb-e5322c553a01",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1bb71e31-80d4-4ede-9b4a-8de2f14c57b8",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fface45d-e854-45b7-a64e-ebfa76673e2f",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "95317c1e-bcd1-4c22-b5dc-4d70ac282d49",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7cbc7922-4b57-4ae8-ab87-1b72485dc0ba",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6b299ef5-ed2d-4d6f-af2d-cb2cf83bfdc9",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1ab61a93-f146-4e9e-818e-a73ba3e57d2c",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "90017818-9a53-42a4-b7e2-ad09fa176a89",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "23f7d289-e583-44f8-9843-73425550bfea",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "3f3f0311-96ce-4f51-b62a-38076536497b",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "301d5486-f4cd-4afd-9709-3b2f06438c32",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e0897416-5a1d-4f3d-bb4b-8e8623b50870",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "08499b27-bc30-49dc-86fb-705e2555b8d1",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2fbc9673-79c8-4fec-98cf-03b4fe5ab096",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8bdc2ed0-dab6-4ea3-839f-ec1364e3bc4d",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "21d32167-1e13-4b57-98d8-82dc221d3e42",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d83d390a-db33-4107-8af2-0926641eba3f",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "be9ff3ec-687f-4f83-8348-e8c051d44149",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b9272cc1-11f4-4440-afcd-8f6ac010cfb0",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ea5041dd-70ce-4e5f-b547-a441d9d2429a",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4ed399ad-98bd-4540-ae23-d6a4819d5fca",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5801ac21-af73-4fef-8f85-81bd118e6c18",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "a9dc89a5-85a1-4b40-b856-0e950c29f24d",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b890c6f1-8c35-46b5-9d25-a3fafb7d2877",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d43cc83e-aa9a-4b21-be4c-e12b49f4ba97",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "80edd369-9027-4924-8a16-2ebaf0c217e1",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9a30f5a2-b671-4cab-935c-e3221543d9d7",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33c23dd6-7dd8-4edf-bced-624a64bbe5a8",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9c4f4b39-e5ec-48fa-9bba-0238f2a8a134",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "419dc652-04a5-47d9-b2c2-db943ca64214",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7edefece-acde-49cd-8ae0-ad7d278d677b",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2f59aa00-7bed-4048-be61-2ca7cd347470",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fb824050-781b-4039-9afb-8c842605c9e2",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aef34d6d-cd84-4d1d-85c0-8b69c75d11ec",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0a266f95-fb1c-4227-b6ce-4a85e7810fb7",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Проигрыш-2",
            {
                "name": "Часть 2",
                "index": 1,
                "bars": [
                    {
                        "id": "4e1b8509-1da6-4240-bd0e-b425065d58b5",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4489dde3-398a-451d-8ea8-c071f2a2896d",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a543a2f0-8e27-48b8-be27-e1e1d4083fb8",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fb8c9879-630a-4d5a-b08e-7e0b641dfd25",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "85978496-4424-452d-977b-3bbccea739d2",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "83dbb2d0-2972-48fb-a537-ed4376de1fcc",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "09281b24-f9c3-4ab2-9e87-c552905919c4",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a5f0d6f1-4303-4498-81e9-f0470a9548e9",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "01927649-f75e-48dd-9162-887e8442713f",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "47661c3d-ce4b-4b07-951d-8bd50bdc2591",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "cb071cce-0aea-4e20-8dba-3266260f3b46",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2a4695fb-e76d-42d8-9693-a551dddb2bda",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "15df8feb-13dd-4828-b582-10082454dca3",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9242d3be-b2c2-479d-ac23-7886bb93ae62",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2a0f328d-2ac5-4d19-a3e2-919e77dd9f8b",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "e3ab5666-c95b-4275-a25b-ef5226088766",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2889365f-9f00-47fa-b6c9-09215bcbdaf5",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "6819c38f-2709-4a47-aeeb-5337e927ff7f",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fb8c9879-630a-4d5a-b08e-7e0b641dfd25",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "85978496-4424-452d-977b-3bbccea739d2",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "83dbb2d0-2972-48fb-a537-ed4376de1fcc",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "09281b24-f9c3-4ab2-9e87-c552905919c4",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a5f0d6f1-4303-4498-81e9-f0470a9548e9",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "01927649-f75e-48dd-9162-887e8442713f",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ec756a5e-ad87-4a5e-b84d-9b86016aa7b7",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f99870bd-79a3-4d30-a669-3e7f093b3048",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "041e519c-9b1e-40aa-af3a-3610d4785680",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "400e4f3b-7942-45d9-b49d-8f620819c22a",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "70b29838-c1e1-455c-93dc-6071752a482c",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "683afceb-df26-4ad6-881f-712288d4beb1",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "42046c08-53ab-4ff8-b9c2-72f73db5700e",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2b8e86ef-2fdd-4fa9-9e33-5254cdb960ce",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "61187630-8cfd-4713-af9e-263a9fadab82",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fb8c9879-630a-4d5a-b08e-7e0b641dfd25",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "85978496-4424-452d-977b-3bbccea739d2",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dfc81e5f-1f07-4e2e-af59-708e6dd10efb",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0b2c579f-ed5b-46dc-8abd-8d9d4381e117",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1299f31e-4b8b-4e18-b88f-f6497cb4535a",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "01927649-f75e-48dd-9162-887e8442713f",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ffaf539-c7fd-4195-b09f-9f928fdb3049",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "86b06fbc-980a-44ab-bd1b-ac28229d8afd",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "2b",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d496a696-f783-44df-87f3-c56c05d41e66",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "47674384-f0fd-439d-8eb9-a63f13425962",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6aa778cc-eb0f-45dd-8910-5e08d6922adc",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f804e58d-006c-47f5-9432-e74af648c791",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "64b358c5-126f-4e24-a19d-efc49665d40c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "51042bbb-d73a-484f-bb69-aeb72ea31ea1",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "8fa02303-c016-4f47-9102-76466979142d",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fb8c9879-630a-4d5a-b08e-7e0b641dfd25",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "85978496-4424-452d-977b-3bbccea739d2",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dfc81e5f-1f07-4e2e-af59-708e6dd10efb",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0b2c579f-ed5b-46dc-8abd-8d9d4381e117",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1299f31e-4b8b-4e18-b88f-f6497cb4535a",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "01927649-f75e-48dd-9162-887e8442713f",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "81145ef0-1671-47d7-bdaf-6dfe3515335d",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e2984828-7a9a-4875-9d52-b0d70d871762",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "984f63ee-d3b5-4a19-aa5c-0ef05146a329",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9dd4da4c-649d-451a-a307-f47cafc1dfdd",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "83569766-5bee-4fb0-b040-6d8d03ad7c88",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dda6b80a-a2ec-4773-962e-8bbbc6b753d5",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "5f137258-9658-4147-ad28-5b68a2c788f8",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c2675364-a1bb-45a5-8701-9edf0ecc6394",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7e3d7b18-b814-4b30-94c5-16dc83fbd476",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "39c10fa7-fe7e-4542-92ba-b5003e6b7ca1",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "05bdc6f1-620a-4b0b-9e9c-0fc0754a2755",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e49d6d9f-4eec-4e3f-bb81-b876fb356d57",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d7b6c524-fcf2-496d-a990-9b33ed0a290c",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f8b001a0-d3f6-4c86-9b04-50f228fe5ce2",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3efc61d6-2f14-4a2d-8c24-077bc87df139",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ceda571f-0898-4443-97a6-7a3da48d287b",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a2a6a7b1-143b-42ba-80ce-79592df6a170",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6281e837-8ba4-48c2-952f-5d14a0845eeb",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "32c90d90-2143-4bc8-ab60-feeb8d5ee41c",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "30827f37-240d-43dd-8c78-66b1783dfe73",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "9165bb5a-c1f0-4065-b22b-c248362551fd",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c2675364-a1bb-45a5-8701-9edf0ecc6394",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7e3d7b18-b814-4b30-94c5-16dc83fbd476",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "39c10fa7-fe7e-4542-92ba-b5003e6b7ca1",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "38d02097-584d-4b13-a48f-b5bedfa5fe34",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "61eebfbe-ea4b-4283-967c-d1a3271bea84",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d7b6c524-fcf2-496d-a990-9b33ed0a290c",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e327a35a-8852-4039-ae8e-a2c811a7cf27",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0ac311e8-71a9-41a3-b940-87a8d2114876",
                                        "note": "a",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7712b59b-6410-4a25-aa78-da576c52fb40",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a7d5e55e-4e56-4236-aa1c-799f1c782545",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "19072654-85b8-4b5a-932f-75f9e69f0dbd",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bfde6925-187b-49b8-9d0c-6d98b632e584",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "02df2981-0f15-470a-b5ab-832bfba8f856",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "1019f282-8be1-4c47-ac8c-a58cc93268b8",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "951ea342-6212-46ae-b1da-8af6485b337b",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "40f041e2-c9cf-4612-b057-b04e5a1480bb",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2e7a40de-bb14-4771-89d7-5cc92ff2342e",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5940a31e-bf79-4403-a5ba-375f7e5de844",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1eb7dd3a-0b72-417d-bf0c-72dd72808e64",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8f308f2b-3c2d-48d0-92d7-b894fda49f35",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0ce7814a-d611-4015-9a9d-f1b2673af867",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "029b5ff8-cd82-421e-b4a7-43598065b0b4",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4f8b77d7-af9c-4aff-bd09-02971a9b4d86",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f5e6ed64-b6b8-4a06-bd27-ea04fa8eb769",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e609c09b-4eaa-43e3-a49c-0776e0009013",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2b7aced9-2c03-46e4-90ce-c2dc71421431",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e101ba10-3d01-4cf9-bb69-3ad6521819ab",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "249e7c3e-f6f2-47ca-a967-15661f6c4ddc",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e67b9522-4860-43e4-b764-31ca762e466b",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6e232128-c46b-46d5-933c-5994e76d9ddd",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "edc28e0a-184c-4a8a-9b1c-806f7c7f9e03",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7b14bf5d-3f91-4268-aee2-b58b492b6b7d",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "603f8be5-e789-482a-beab-57b84d9d4f76",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9f8c417e-d827-4a2f-b043-74a4d0114f6f",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9f486f3b-8946-4d81-aa04-066566b81dd0",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b1caa784-9552-496b-a157-fa9fcf2c35af",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bd84fbf0-e3f8-4543-8efa-3fc13a75f874",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "119c40e9-95dd-47d4-b61c-38e23b3edd9a",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5082b869-68ea-43e7-8b11-cc61eadb867b",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "34082c9c-df78-4677-badb-eb73ed6d0874",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Куплет-1-1",
            {
                "name": "Часть 3",
                "index": 2,
                "bars": [
                    {
                        "id": "50446bf4-f261-4b7e-8ef8-297cd2c0865a",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ec079901-1390-4864-bed8-3e8faf1a662c",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c eb c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2beadf60-a1d2-43b3-b6fb-02be1ef8bac6",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "62981b1e-ee8d-4ab8-bf16-f55eb1edc915",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4768ef7a-c27d-4515-8e9f-2d23e395fe7f",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "787a6b29-b700-4260-a500-ca9e786e9e18",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "cd75d6c9-b867-409f-8b1e-ad945f6c210d",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "105d78cd-7095-4ff0-849b-08f6e791e5df",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4b5aa95b-13a6-428b-a4ec-a9f888474411",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6eaec7e8-06fe-4054-b5d6-729ffebe1022",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1c78734d-4ee7-46d0-81c5-8511645d1211",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2bcd7891-fcb4-48bc-8f5f-4d67e8fd5097",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "19abe65c-88cb-418f-9548-3e2961905c41",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c eb c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "aaf48db3-31f1-430e-9f70-e2f1b81098c3",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f7d06967-e8d3-42b0-aa20-6b74a71951ff",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5c8bcbf1-f70b-4f81-b45c-45fbe0ef39dc",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "904f3ff6-0376-494c-8c76-dffed5ccfffe",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9abe5466-1357-4e9a-848c-d6f7613d55f2",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b98192b6-62b6-4f92-aa2b-31f16f13087b",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "922dfa7a-c1ca-4991-800a-bf0951d6a4a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "68aeeb02-5c2d-498f-a247-81b7220c1fd9",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "593325df-e440-4b4c-a77c-dd14212526d5",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8ea32d02-9609-4f50-8cee-7e6e81d0a1fc",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g bb eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "daffd4a2-13ab-47d8-bda7-f04f3d0692eb",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "edf9c286-b138-4328-9d1c-b4072c557849",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a32da64b-5834-4671-afe3-5fad45fc5c6a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ee78da66-dd13-4212-82df-559d86a444d8",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b49da039-8897-42c1-915d-6b4a45cc7d23",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3e1a1e72-a995-4997-ad04-561787bd293a",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1b695327-7e36-4be1-ae6e-6ad57fe64026",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "97477963-d33f-4c17-bf16-4963010af56f",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "af7f9285-4cec-47ba-b4a2-8568bb78731a",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "53c67656-d589-44be-8065-2b61f9d99c6f",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "a482cf19-1077-408a-831c-5982bd8c3be1",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g bb eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0d59cc4f-8b26-4d91-8850-d9e221345421",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "617fcb14-7d4f-4a1f-b7fc-d1de88e42a9a",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "9b7dbb7d-c93f-428d-8432-6f144191f8da",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "69f39516-9560-4881-81b6-d7d508846a8e",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "754bb1e9-61ca-4a30-bada-f234207d09d9",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "262d5fe6-8982-48ba-9081-d68c7c53ecec",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "66756bad-9227-4a55-925d-d80edb3f0c7f",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "901d9230-099f-4dcc-b9f9-ce23b61bb9ad",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "494a162c-92bb-4652-855f-7b6f635f1ffa",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "84f944e8-65e0-4978-a848-430ccae910d6",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f219dd6b-a1cf-4209-bd87-54ebe8c68e88",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f ab f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9b1dae68-139c-4c56-a42b-e35d179232ef",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "db7d298b-1e77-47d1-8eed-a87d73ee07d3",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "08bbc0ed-5c5a-48aa-ade6-f96e05a0209b",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "76e818d7-b601-46bf-8d89-7588c399139d",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "675be337-d7ba-4d7f-ad5c-ee21a1032500",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ac2c2f56-33a2-404c-b5d5-028e3aece605",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fcf00e47-134d-43cc-9b9f-03e710749ce7",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5f840ead-28df-402a-bd87-1eb12178f4d6",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aaa03b93-30c7-47a9-a2fd-5360215979d8",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2fd63bc6-91f5-4c80-a05a-6c76770ca6ff",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "768e4e50-657e-475a-823f-5f8531d5eb54",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d3c4f3fc-bcd7-4ce0-8c2e-6d55cf0830d0",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "01155b26-eba6-4cf5-8a12-b84ad6bd4f72",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dbb2e195-2f8e-441d-984c-d525723e62a3",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "733fbecd-9252-47c1-bf51-21adc822e115",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b33a2d6c-50b7-4f97-969e-6bcc18937b45",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7404165e-f15e-4486-8f3f-c2257ce052d9",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "01e18022-6550-4951-b35e-c49bdd02c17a",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6c9c1fa9-e0c1-40e9-b72e-3dd486666f51",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "faab2722-2176-4446-b388-84004fb0407e",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "d160c1fa-f631-4b9b-babc-eb21f0eaf221",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "41b8ca13-b104-47ea-be53-0d34dde3be54",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4 c5 eb5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c7432ad9-0ec9-4d53-a606-371bb43a885e",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "42232913-9e26-4d71-b7df-f044c770fcc4",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "eb7331a0-f9d0-4b69-8d88-30bfa4b1ada2",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c6ead383-45ef-4c84-b504-3ad5341712fd",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7cd7df29-e48a-47db-9bd8-aa8e835e5f54",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bfc6f28f-5170-44cd-b956-872fd0bde0a2",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6d33110c-012f-47b4-9078-770a62d4a068",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d1e50374-5667-4a1d-abe3-4ed6bc9a82ca",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c ",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "93dd1cdb-876e-4aa8-9010-68d86f7ad5eb",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d942c0ae-95c4-488c-b80a-263e0f359c27",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b41ea4e7-aa39-481e-8386-95f369a8e502",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a187d54d-62a2-47a8-9c36-421f46a1e671",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "f285bef9-ef96-4e96-a520-be165b3e0040",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g c5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "894d612c-6c9b-48e7-99e7-c3e1d96ba4e0",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bd5d91ca-8ccb-45b3-9cc9-a25d00091d83",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "11f37d8c-504f-4ee7-8cfd-e56201af9ea0",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c6dd21ab-23f0-4dc4-87f9-cb6383af9c3a",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e996f69d-259f-4ab7-af4b-d9a1da3b8bd3",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a22fe23e-11f0-4782-a830-07ad49c6d105",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e27c5189-0bbe-4ff2-ac94-12b658fc519f",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7ac7ee0e-cd20-4d1b-9ae1-67f7484412bb",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "894ea8aa-5979-473c-a210-9820807faaaf",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a949a5d0-faf8-42fd-b8b6-203ff50054a1",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1d804a5a-7668-413f-ab83-6e95c919909d",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Куплет 1-2",
            {
                "name": "Часть 4",
                "index": 3,
                "bars": [
                    {
                        "id": "ccd3809a-0378-4278-9882-eca261996e9c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1c2346ab-ff31-443c-9978-abe4c9a75229",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g c5 f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7e4ce304-55a7-4a8a-9fa7-17b11960579d",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a61e1749-9982-4e52-9001-71b17daadac9",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0553c4e7-9474-4aff-9011-dd2511186a4f",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dba374b0-03ba-4a12-a981-9d55c57fb3f0",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0216762f-f4c8-464f-bda4-f28e58f86f38",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e9d60912-272c-4c65-a3d8-5f04794c4193",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fc18f270-32b4-4f01-a74c-a4dbee377acc",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "987734af-91ba-4e7f-ad47-0f6e1acf6891",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6cdfd519-9157-42a3-a1f7-834dcfa56de9",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d98ac8e0-db41-4e35-8ba8-c17c3051aad0",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2aacd8a6-0bb4-4f93-b87f-6591ff944daa",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "5096a944-405c-46a7-861d-a39d428fb7a7",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e7c41aa7-d608-47d7-8fae-e6acc1bbee41",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cd2efa5f-3a60-4171-a063-5073d082b696",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "46238e1a-5b4f-41af-9f7d-20ccd753db8c",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33b0f7ea-2cfc-42ae-b608-df76a2069abc",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a5ed5b19-c795-4e60-b8cf-e95b2bbad7be",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b2553b1d-ede0-41f9-bfe1-1c6e6080c277",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b5c6f633-cc9c-4109-9120-429ded458ffb",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e70ba4d8-26d9-4349-b296-1946f41245e5",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4b89f936-4cf2-452d-83d1-81e7e8a65ead",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9cb111d0-416f-4316-9f9f-5d296dc55072",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "672162a3-c412-4319-8709-f015b96b5e8c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "47ba94d4-69be-415f-bef5-bd7512db745e",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g bb f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "85277f9d-025d-4ad8-9266-a40fea9bed99",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d4068e86-1a6d-435c-bb24-c6bd4d7404bf",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0795fd59-202e-45db-bbc4-bee4b182c39a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cb216385-b37b-4888-9f20-c94d8526ab4e",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "32f54c56-db41-4dcd-a444-169e4df323f2",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "80f049b7-39ee-496f-aef7-3314ef4a3fe4",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "53bf2ab5-cf74-4a37-8570-9790b58e988a",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6c9d2335-e93b-4900-acd2-85d9c84b4374",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e6ee7e0b-2e25-48a0-b1a0-ee2327658e34",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a58e5944-96cb-4bcf-a426-61147b4f1645",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "be00a129-c49c-4462-8cff-7ed832989ebf",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "fc593d20-6233-4bd2-b1b7-bcc5a6eebb4c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ea454476-4647-4600-90f6-0a31f9bed7f7",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a8eeefa1-1df5-4341-9fb1-3e57f0a75c32",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            },
                            {
                                "originalText": "bb eb g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "41259536-fa8e-4513-91a3-f9c61135ff45",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e769e9dd-3200-4613-ae76-4e05f8544edf",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "16ed72ed-ffa5-46ac-bf84-db10b6d85d5c",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c08cd2ad-0191-4bbf-83ea-cd8e4c11bd4a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "60b98851-2f83-455b-8e91-319db18e72c1",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "adc3150b-e09c-490e-bdc5-98934045c768",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7255d7cd-88c1-4506-ac64-4eefa87c8f01",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9731aac-55f0-4516-91b6-1e75a8893b88",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "21f5c6b0-abca-41ee-a271-27784ae0493f",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7756756f-47ef-424a-a1ad-6ee5f9b25df0",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "8e84f226-b3b4-4562-92e3-5ccd1093d2c1",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5ffff77d-7a0a-4ff2-bb3e-5910fa4c6c6a",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab c5 ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d8798987-bf3e-467d-a8ac-e8e7d5e557e5",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7d0ac92b-1664-4404-9c5b-3449c7964212",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5ffcd2d9-ae75-469b-9e12-f8bdce85a327",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5951fbe2-38f5-491a-a70d-206e54240ba1",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d6857c72-ca77-42e8-815f-2e4165938db6",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b5abb01a-5adc-4cb5-87a2-f795ba8ffcc9",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3981e81e-6339-4de4-9571-5b5f47edc78c",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6fb2adbf-d7c5-4d1b-aa54-e9cf32766fc3",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dfe4838a-824c-45ce-a2ed-ee04186744e8",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f80bab8f-2a4f-42a4-b531-58480e508344",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "c96e6e4f-0c7e-48de-b8d9-49ebdaad9015",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2757f780-8ca5-4e9c-b9ff-0b054fca77d1",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "219f7790-e9f4-4a3f-b2d0-52d4501e2ed8",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c95a0a48-c5f0-451c-92ed-c9f2bfa9f85b",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d89eeb00-31f8-42b6-811a-e603ab84d602",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "eaca75cf-cbe2-4610-9b90-ad6a9c9a0ce8",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f3be233c-9720-43da-8ef3-cc53a6cad310",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3f86cae5-d565-4bde-8955-783012453e6f",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2f2b3807-2a9b-4297-865a-a1d0c3e9dcad",
                                        "note": "ab",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3518174d-c6c4-4766-9aca-d1b4d7a3888e",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "6f086852-ccb1-42ab-aad5-de2d3dd20abe",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d072c8e7-a5ef-427d-b06e-213d3439e7dd",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g c5 eb5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8d8561b9-bd6b-4f16-a932-51aa3f60370a",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a39a8087-d35f-403c-8c2c-40b6d6cee8ac",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b4833134-7c82-4e90-8bf9-639d00822681",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "3355da9a-b0da-44c6-8dfb-bae0e25384a3",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb bb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "bbc1595f-d854-4f3b-ab46-315a6a51baeb",
                                        "note": "bb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "22e9921b-0517-47c1-9fe8-cddf75d79412",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d912936b-da4d-4616-aec9-7811252ce008",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "43af5485-6462-4643-ba3d-265d3936c707",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "687fd8ec-d322-4476-a589-35876b9c2b0d",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ec5ac58a-4dab-4828-bb20-f397cddc040d",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "906583b1-7573-4a03-845d-5271c3d0cea3",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a6324257-d217-428b-b185-f12e1906f196",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "8ffa6421-dff9-4b1b-ad80-03bae84ea5f3",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g c5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "875d6e3f-5b8d-47d7-9c98-ac87f21b2257",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "cc8c7401-5a52-4162-a20a-fc9477d1d2fd",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1e41a3a3-3408-417e-9437-94be1d094415",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5cc3b5f5-ceb0-45d0-9d2b-6a07b0b40113",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5ed7a6ff-daa1-4601-8e23-976653f494de",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b4156500-0cfd-4e6a-b8b5-8677b984a083",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2b6c1e4b-437e-4a55-8600-56311ecc06d7",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3bf4ac11-62ba-403f-aa82-a3c7fa5a0750",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7672ed05-ab97-4a99-acea-da7b20a14915",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0b9bae04-6484-4dc9-8021-98e4f75ab328",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "91b39d80-bfc8-4bd7-a008-140dfce5d8ee",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Припев",
            {
                "name": "Часть 5",
                "index": 4,
                "bars": [
                    {
                        "id": "eaa8786f-eec9-43b4-8f6a-b4b64121796d",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "76231f5e-e611-4ab9-a76d-9b41fdf0bb7e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6f553df0-b939-4848-b02f-b842d4ca3bea",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3e996eb1-8ffc-4133-8d68-26c4b4a5bd7f",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ccc6b5dc-f395-46e3-865b-262b6aa39bb1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28eee430-56ac-4429-921d-68bd59cdadd9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e128a93-97a9-429d-87d3-95f005e11e84",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "591203fa-b9dc-4261-910b-7ac73fdbdd60",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "af1f102a-af55-45ec-9afb-dd3d33dbba2d",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dd869d90-3c8d-4016-87d8-7e05d3e5e497",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "c676061b-28b6-48af-be75-42d46ba957e0",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "728865ca-1d86-4f7c-ae34-3cb04ebfdb0f",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a3 c g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ecc5edc1-46e9-4c80-bdfd-ee0596baf835",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "162dacb6-1fd7-4c3c-a44c-7d883110918b",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4b81ec5f-f95a-4063-8d19-371f540b9226",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fe718164-3aaa-4b2c-8e9c-96e473cd1c0f",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d93101ea-5d22-413c-9549-709efaac6fab",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "901c4029-0d8e-4c69-ac2b-605c3d50db4f",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "13fc3d47-14b7-4ee7-9343-9b04ce590d26",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0fa979de-df3c-48b7-adbb-70422017e20d",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "3db45e0d-3e45-4461-916d-cf804b839104",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "76231f5e-e611-4ab9-a76d-9b41fdf0bb7e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "784fb83d-20f8-490a-a016-0b06ace23fa3",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3e996eb1-8ffc-4133-8d68-26c4b4a5bd7f",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ccc6b5dc-f395-46e3-865b-262b6aa39bb1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28eee430-56ac-4429-921d-68bd59cdadd9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e128a93-97a9-429d-87d3-95f005e11e84",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "591203fa-b9dc-4261-910b-7ac73fdbdd60",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "af1f102a-af55-45ec-9afb-dd3d33dbba2d",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dd869d90-3c8d-4016-87d8-7e05d3e5e497",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "2ba4d584-bc57-4589-8381-7a499d96d963",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "bc45b39f-e275-4149-83ce-0cdf7a3ea343",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "59d14310-724f-4f44-b5d6-273b0fb2b62c",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f9ece4b4-dade-415c-82d1-0ad8ba0bb8a5",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "67d835a0-b63f-40c0-ba8d-cec0b421783e",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "54f65349-4ad4-490b-994a-79d4be89a953",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bb439f2c-581a-4824-8cf0-042ca81404a3",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c101677e-cc4c-4d6f-b68d-4190ffda8ee1",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9ffa642a-021b-4c4c-842a-d59fad3027b0",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "29a43fa4-2e5a-4b1b-8ef0-2e96f81e9804",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c999a5aa-e002-4b54-ade5-057b57fdf5b2",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b8c2581e-97c9-43a0-9bbc-c8ccf9b65a34",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "67d7f2ab-df51-44eb-9452-c5c47801e111",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "76231f5e-e611-4ab9-a76d-9b41fdf0bb7e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e69e2a4e-9bc8-4ec0-8740-2c6bcf380f41",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3e996eb1-8ffc-4133-8d68-26c4b4a5bd7f",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ccc6b5dc-f395-46e3-865b-262b6aa39bb1",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28eee430-56ac-4429-921d-68bd59cdadd9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7e128a93-97a9-429d-87d3-95f005e11e84",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "591203fa-b9dc-4261-910b-7ac73fdbdd60",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "af1f102a-af55-45ec-9afb-dd3d33dbba2d",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dd869d90-3c8d-4016-87d8-7e05d3e5e497",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "dfadb557-93c6-4dbf-a26b-13a3502d436d",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "728865ca-1d86-4f7c-ae34-3cb04ebfdb0f",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a3 c g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ecc5edc1-46e9-4c80-bdfd-ee0596baf835",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "162dacb6-1fd7-4c3c-a44c-7d883110918b",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4b81ec5f-f95a-4063-8d19-371f540b9226",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fe718164-3aaa-4b2c-8e9c-96e473cd1c0f",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d93101ea-5d22-413c-9549-709efaac6fab",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "901c4029-0d8e-4c69-ac2b-605c3d50db4f",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "13fc3d47-14b7-4ee7-9343-9b04ce590d26",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0fa979de-df3c-48b7-adbb-70422017e20d",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "fb0bf867-8429-4057-b979-ccbfde1e42bf",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "eb bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5e6f9118-dc18-4958-9f8d-27ac9c1ddd9a",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "270a431c-eb21-410f-af4f-227240145f4e",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5acf39fc-d305-4868-9ae7-ff24db6395d6",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6cc054ce-0329-4930-80fc-fa5da181faf9",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1ef965d7-2c0a-4f1e-ab3d-750c65e86321",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28eee430-56ac-4429-921d-68bd59cdadd9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "26975662-2d29-4d5c-821f-f3b0c3383688",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "12d1eb9c-b3fc-4307-8b8f-eb7e5711f2a8",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bf7f5e40-9524-46d1-8ed3-7e3e08373ed9",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5845c78a-e617-4c61-b991-0d68f49cef9c",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2e740cad-523c-4d78-8cd5-5f2e26bd379c",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c45ac576-62fa-4cb6-a1a5-cf4875173fdc",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "a13cda3a-23bb-44e1-963d-b52decf359b3",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4cbc63f6-a0f6-43af-884c-f906def0ccc9",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "871a22dd-6258-47bc-93dc-bcd553b5a553",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a28938a1-2377-4e9a-8851-75c5184e66bd",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "609427bc-3373-4d46-9236-be1e418a424d",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "80e1f012-e0a5-4cab-ba77-a5f1160e87b6",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a42a864b-a902-471f-ba97-dddd79e16aa6",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "67d63a27-b61b-4c40-8dcb-af4055a8b0c0",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ccb0976f-6b09-4e1f-8cb2-2999a6fd0489",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "27d2e263-6a7f-42d1-b8d3-2777be3bc34c",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c3e48524-044b-4663-80ad-d6e7e5824203",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6b7449f-ffce-4258-b409-b6e06230bbf3",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "78ad02db-27b1-4765-a216-a5c6bc957d76",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e5592ce1-a477-49fd-a36e-066a2532d684",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "21e8bb59-5e3f-4f3a-ba3b-e70d38400f43",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b1cc8ce5-e4a9-455d-aa57-3b28e35bccd4",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c6879130-0c43-4e97-a3d0-4c4d2362521f",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b1def318-d695-4099-9e47-b1f87e7f2497",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9d21f633-879f-476c-aa3d-3a5e90d1bbc3",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a1e68e07-4044-457c-a1c3-13a289cff094",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "44952804-dfa7-4568-88a9-44a035c4638d",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "541c8b78-d35c-4da3-b17b-06a80e4b31c7",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8ae701fe-2424-4242-9fa7-f8c67e0e95f8",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a69930ce-274d-41cf-917c-164e4b30bff9",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "514c4929-78ce-402e-9df3-37e73195f589",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "bfef86ee-6f10-4376-9f50-b5369ce81eab",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f06852aa-8097-48ed-85e7-ba0ff2958a16",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a28938a1-2377-4e9a-8851-75c5184e66bd",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "faf112c2-8cff-4e39-bc57-6633a9bb55de",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d263d972-1035-4751-87da-ea3078c71ec9",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a42a864b-a902-471f-ba97-dddd79e16aa6",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "67d63a27-b61b-4c40-8dcb-af4055a8b0c0",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ccb0976f-6b09-4e1f-8cb2-2999a6fd0489",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "27d2e263-6a7f-42d1-b8d3-2777be3bc34c",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c3e48524-044b-4663-80ad-d6e7e5824203",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6b7449f-ffce-4258-b409-b6e06230bbf3",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "5d41d5d0-9cdd-4f32-9355-30b177b62fba",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3d0e91ef-1cab-4b1b-8f63-08435c321d7a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7e4d20b6-f4d7-4fcb-a5b1-7ff5a7665aae",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "07d2f12d-d7c7-4268-aeed-21965398d2d9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f6f788bd-adbc-41bd-b98f-a4983c4f5ba2",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "44952804-dfa7-4568-88a9-44a035c4638d",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "541c8b78-d35c-4da3-b17b-06a80e4b31c7",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "98e24e67-518c-4f85-bcef-4f22257a9146",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "04687b29-29a2-4c53-94af-7fb662586564",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "d4f2a8c9-0786-4004-aa65-0edc23c93459",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "bc45b39f-e275-4149-83ce-0cdf7a3ea343",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d g d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "58a2d2b5-59af-45a4-9ee4-0fd9a563ef01",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1888843b-c88a-4f89-bf04-5111c323044d",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d7f8f891-891b-4c4e-b4a3-ecbd2e5c578a",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d g d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "136ac73a-47f0-42f0-a412-d90e3dc22002",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "70ac7b0f-2131-450c-9942-63b8ad2c797a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "8637ff9f-fa77-4bf9-9214-465ad6a7a9d4",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bb439f2c-581a-4824-8cf0-042ca81404a3",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c101677e-cc4c-4d6f-b68d-4190ffda8ee1",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9ffa642a-021b-4c4c-842a-d59fad3027b0",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "29a43fa4-2e5a-4b1b-8ef0-2e96f81e9804",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c999a5aa-e002-4b54-ade5-057b57fdf5b2",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b8c2581e-97c9-43a0-9bbc-c8ccf9b65a34",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "a6f11a29-3d66-451d-b2bf-84e98f91085c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb d",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e5592ce1-a477-49fd-a36e-066a2532d684",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "21e8bb59-5e3f-4f3a-ba3b-e70d38400f43",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "55a2cd8e-259a-4587-920b-b5c4f1e0f020",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "442f6750-12df-4782-9575-bd5f1b5e2910",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "df841858-cf00-4ae5-a7d6-b88dc0fdb4bc",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a1e68e07-4044-457c-a1c3-13a289cff094",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "439ffbfc-d549-463e-b4e1-5e32fd6d73be",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "44952804-dfa7-4568-88a9-44a035c4638d",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "541c8b78-d35c-4da3-b17b-06a80e4b31c7",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "97cef3d7-b98b-4ca8-914b-7477acc69215",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ca3843b4-6b33-4527-8e92-d506d2dc7771",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b294eccf-2ccf-4261-92f0-7bdc32e9d107",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "8eef2c3a-6e93-4c24-9946-f123863b9caf",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "728865ca-1d86-4f7c-ae34-3cb04ebfdb0f",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a3 c g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ecc5edc1-46e9-4c80-bdfd-ee0596baf835",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "162dacb6-1fd7-4c3c-a44c-7d883110918b",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4b81ec5f-f95a-4063-8d19-371f540b9226",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a3 c a5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "947681f0-ef76-4af6-afbf-53415a5bd831",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e1c973a6-adf3-4117-8329-b7f772748067",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b9cbf52d-aa85-4a92-ad24-09627d55464d",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d93101ea-5d22-413c-9549-709efaac6fab",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "901c4029-0d8e-4c69-ac2b-605c3d50db4f",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "13fc3d47-14b7-4ee7-9343-9b04ce590d26",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0fa979de-df3c-48b7-adbb-70422017e20d",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "7bb2cd03-c0de-47d9-88f2-0b9b76479cc5",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb3 eb bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "af0e4ed2-13ee-4705-bc5d-c4b2c1d0e414",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f8f7caf6-66e8-4fd5-b9da-4c589e2d4bf1",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "85d0e0bc-a77d-449a-8d6c-9fb15376d9c9",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5acf39fc-d305-4868-9ae7-ff24db6395d6",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6cc054ce-0329-4930-80fc-fa5da181faf9",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1ef965d7-2c0a-4f1e-ab3d-750c65e86321",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28eee430-56ac-4429-921d-68bd59cdadd9",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "26975662-2d29-4d5c-821f-f3b0c3383688",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "12d1eb9c-b3fc-4307-8b8f-eb7e5711f2a8",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bf7f5e40-9524-46d1-8ed3-7e3e08373ed9",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2b7326ad-e3c0-4844-8bb7-27bfe117050b",
                                        "note": "eb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "399c0fc5-b095-4cea-90dd-34f65c05bc88",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1d381e2d-2d67-4340-85ea-705999bc4214",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "dfca7531-c219-402d-8e3b-48af1b419d61",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c f c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "80c0492b-36be-41ba-b93c-03a07f9fbc6f",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "923b8b2d-33bd-4efa-8394-1de38df2a529",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f9a07904-95ee-4a12-a8b6-14ae3ed55494",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "49aa8d35-164f-4fe1-882c-fa56056209a3",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7b550ce1-6b08-4726-b191-0e06e170995c",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6192c95c-326c-4a72-bdd0-0cc61cb3ec41",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7ed3845a-6032-4e6d-a78e-f8234ffa69bc",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b83272eb-f87f-46d7-ba90-fcbf14bb416f",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8c07fe49-89fa-4e21-9116-d99939c896d1",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7b001fa0-e42f-4827-b05c-570ad99ec69a",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f49adef-a390-4502-a5b1-4c4f8bfa9f89",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Развитие-1",
            {
                "name": "Часть 6",
                "index": 5,
                "bars": [
                    {
                        "id": "1f254d79-9f24-49c7-abc2-e2caf6bb4791",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "663711d6-61ee-4a04-9dd5-c107c593eb83",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1368ff1e-b1f5-44b8-a58d-de790ee1e944",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d8418ecb-0096-4b5e-9304-17c80543f492",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9d742840-19e8-4cd2-9a10-d459f872324a",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5dad5a40-55f0-423f-8bac-d546158d0b5a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b4571241-12a0-4a41-8286-fa35101b0d6b",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4219b7f9-4bb6-4f92-a097-74cecaa290e5",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "78c85147-2c47-4166-985d-9cb5ec163deb",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a74718d5-29e6-4a8d-a67c-7a292b93fc94",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "1777bae3-ce4e-4b81-b65a-32e533940ec1",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "746e2d85-79f3-459e-94f2-22723a7dfafb",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "005c0061-ada1-4cbb-b407-688a7ed899f9",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "65db74c1-e8bd-44f9-ab30-adb1888be34d",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "85746ed9-91d3-4183-bd11-696e23a725f3",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "58df7325-63b3-426c-8886-d3b6b02bdf55",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "561294b1-266d-4b0a-91b1-1e1c446bd88f",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8e9f499c-2e96-40cf-bb99-85a89e14e622",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bf84643b-0593-4541-ba92-6971fb1399e6",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "daaee187-f9e5-478e-9985-2c05535df3ce",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c492e961-7a4e-4859-8e30-2879a85faac4",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0afb84b1-6d9f-4bd9-8251-08ef63684168",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ecb3cc62-4ee4-49e1-b02d-819f3c545196",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e5dc61f4-a515-4e1b-8240-bb80a4d397dc",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e381b2ee-75a5-4708-ad17-71be3371c520",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "418012a7-81eb-4c6b-a147-a656a0bb6619",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "72623e0c-ee62-4b4c-bdb8-232b305a3674",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "064b78db-ce95-4d70-852d-a35b4ac18b95",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "494d5dcc-e0de-40f3-ae7a-9d92aa023f73",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2f46a184-a3dd-471e-9058-79283e1a1d98",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "d19ebf17-b044-456e-a123-3aca891fc1be",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9e26d652-7609-43db-9851-bde0a027c4a1",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f7a9599e-d074-415b-9754-8b06140020a2",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "db126036-96c1-451f-8ba5-922fc309c875",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "30371db6-54aa-4e0d-b2b2-a0abe01d1a05",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e6027ca6-0064-46f4-8bf0-5f03aa69ca6b",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "03dc13cc-29be-4ad4-8a85-b56102f1a0f8",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "11da7005-aaa2-445d-9efb-3b998f07d0be",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ed6f56d5-b067-4a5d-b61d-c01d724b1d13",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "b7d3bb19-df70-4147-ba8f-bdb3fd3e6f4c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "ab c5 eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "83b0d514-93b3-4289-b387-1f3714448220",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a8439b39-d741-43b7-8fbe-b8082f8aaf3f",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "24735087-1115-4e41-bda8-461467fde899",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5/eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c0ca0bb8-e4f1-47cd-a16f-a376baa29724",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "86f265a2-d5eb-43cd-bcbf-8de663b2f895",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "067a8e59-85f0-4dd2-a12f-dfc617ead892",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5f952c12-2cb1-4bdd-9fce-b1f4617ca2d8",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "82d42eac-38bf-4844-8d90-2f93ca891e75",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c568d13a-3d80-4d00-8524-047ca6a41767",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8bdf187c-3053-4552-a0e3-b5b4af894f1d",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "06361d1a-16db-4bcb-a96d-e5fa1276006d",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c83f9e00-0e0b-4c94-ba54-312b0abf54aa",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "de0db015-9b30-4652-bcef-5ac68a44b4f5",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "32f05641-b1cf-4315-92ac-9b095a555e73",
                                        "note": "ab",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "45385851-bdc4-47ea-b506-9481cfad9e4e",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ca6b18b3-444a-47e3-a632-af0a11d42a28",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fda1d945-5d2a-466b-ad9b-7e8ec33b30d7",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "241ee826-9727-4012-b48d-a1268b07f6e6",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b6029da7-e47f-4366-9f2b-82ed7419ad51",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e96f2d4f-263b-4776-8c0d-dbeb761987f5",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e1f6f6a2-d7bb-4a1b-9dc0-b82abf62accd",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd56a282-0578-4c3e-8e28-a5ee5389f3ff",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "1dcf6e58-8a1a-424e-a5d3-1683419a2be0",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb d5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cd75fe5d-2a26-4b72-aa34-0e593c0d6b2d",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7b8d7451-22fd-48ff-a187-492f212aa201",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a0e30ebc-0d9a-46d2-8763-044b97de88f2",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5/g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "fd503762-d296-48fe-a2b0-cbd72e929607",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "3e811229-4b5e-433d-8d81-a099183d575f",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "815bbbc6-b764-411f-b0c2-4c9e7453a6a0",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "032215ca-5ca3-479a-b66c-53b454fe4a1f",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d3c83182-eca8-44fa-aeb2-c0666d57a7c6",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fe061892-4902-4088-a220-217f85b3282c",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3bab1133-8765-4d5a-8e1c-23d102026bed",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3b68cd18-993a-4f79-bea4-41aae9f6047e",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "71edb325-8621-49bb-b7a4-669c8697faa5",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "547639f7-5c77-45d0-9fb9-2f5f891ceb77",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5/f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5c618afa-2e96-4587-94e8-1d9019fcd68c",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b8420965-e73c-430e-93ef-dc5a56614bc0",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33a5d2a7-9e72-4516-8e07-9bbf6d4a6260",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e94c4556-e458-4c08-80a4-0d3085ccb149",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ab53f0c2-8fe6-44a8-9a61-fc0701e87663",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "564823d9-4287-40dc-93bc-df6db74caf55",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f2169b60-5929-4664-9db0-b825ec1306b9",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d4126cd5-88ea-445b-83ec-440b3f1a8ac4",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "891c5d1e-eaa1-47f5-8174-0d3de343f6dd",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "175ec152-59b4-47fd-9716-b38c8f4a3e51",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Развитие-2",
            {
                "name": "Часть 7",
                "index": 6,
                "bars": [
                    {
                        "id": "a89fcd77-b7a2-44a9-bea5-309d8985a584",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1183643f-3109-4fe6-9e4c-eef4478e8b3b",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1a8b3525-4775-4af8-9dd3-01728944db61",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dd956452-0d37-439b-af8f-a31260a74e8b",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "118240a6-3ff0-421c-9548-1ea206d39916",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2be5e279-104e-4da0-8041-468ed862e45e",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b070e810-ad6d-4e27-95c1-839cc292a2a9",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a32f6c8f-6844-4957-9927-e7f887aae526",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7b740b54-3d5b-49f1-a887-760a0a1b3bd6",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8c16f387-b6c4-4b5b-a8a4-82ada8147bf2",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "2680da51-6508-47c0-9722-78c4c6314a60",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "48c613f8-053c-49e1-a588-7546dbb54e44",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cc13a7e4-5c5e-4bb1-99f6-47d47c63ecfc",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5 eb5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ae676b9e-48bb-4983-83d6-235670cda778",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "42b5bc57-9808-4325-9831-c72967fec428",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "290a029b-ef45-454a-9db7-2fa261ade21d",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6a1cf4b6-f1b9-4223-91b5-78dfa2f0e8b4",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9f9c341c-9247-4a58-8eb7-6cc9b5187ddf",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d308ef3b-ff48-4f23-86ee-b596c5dd1668",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "43f2e27d-cc3c-46d7-a74c-3d2923e524a9",
                                        "note": "c",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8d289a65-5718-4c7c-bc5e-f3a1a6482e25",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c9d32379-e91f-4227-8e58-2d8daf3ffa10",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "969496e4-e30a-4c0d-b9b2-13d3dfd70e14",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d0833b7c-9a61-4f12-b322-e904b1ac5f9a",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5/g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "12c470a5-d041-4c5d-85bd-b2304859b987",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0356eb40-86d1-4083-a660-aa84106d1a26",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "014d5255-db79-442e-8030-fd9515e7ec8b",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ed2ac467-41f0-433e-8151-c24ec973c7a1",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "24956b7b-9dcb-45ee-b8ca-992066d9d9d1",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0f5c4d1b-7534-49d1-9560-8a28fce284e1",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f445fc8-b661-4642-929a-080933a4833e",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ffbfcda-cb96-45dd-936f-ce399c1f42ec",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8e0aded6-d36d-4ae9-a165-3c1905e317f8",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "f4ab563b-8c39-4d29-a83b-b4de186adec3",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1860e291-c02c-44c1-b10a-0714219fc964",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3658a224-53f7-4069-8b14-2f63462a21e0",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9105f2ca-7393-42a6-bac4-a3d96fb446fd",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "896a137d-0213-4a2b-a6c2-19d4f25cd51e",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "24352dfe-3c3a-4ba0-b9b7-a7f83aeb6b54",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b8de7605-5fcf-44f8-87f3-ae0927b850d9",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8a2aca5a-97e5-4156-bc03-f245e3fef9e5",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7bc647e8-1010-45cc-aa25-90ce9a9ccded",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "af835512-772c-4256-a024-8f20aa8420d9",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "22d8041f-3a50-40b6-86d8-5b2e00a98f4c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c5 eb5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1b3e8e40-6a7c-4d84-8de4-9337b5c4006e",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "61aa133d-5d84-4d18-80da-619a051a6b0a",
                                        "note": "eb",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "69b14eab-c32e-4edd-9e56-7079d2d7cc43",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab5/g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b0138de3-f242-4acd-856d-091d6273b435",
                                        "note": "ab",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e5a591ec-50ef-4c72-8502-4377d7eea031",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "88e58063-0f6b-4262-9dc9-c92cab47ddaf",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b180badc-6f28-4a78-8ce5-5f600b9a5fbe",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f9128c5d-3b18-4fc6-90ed-58cc8927f3b0",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "462766f6-0cc2-406d-9fee-2e2db9e2791e",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7b8b45fc-56bc-4dab-8d06-e18058e52f2a",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "688d42a9-ebde-45d8-b299-c65c32aa46a5",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d851a6b3-d010-4cd1-9226-a15abf9f83f7",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "105d8f37-a194-425e-91ba-9a40cf0cf061",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "c",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f1fb97fc-9bc0-4769-9043-3547b1bd59e8",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8f33a70f-96ba-4256-bbff-328c6d5ee3fd",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d46844eb-8d10-4558-b114-58d6541888f6",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cbca7289-2288-41e3-8c45-c4b2ba73d0d9",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0ee9f03e-3f55-4999-b4ac-93ff2f732702",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "078b8efa-27cb-4675-aff0-1daf57f0461a",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ee3b4e8-1cf0-4c00-a880-f4b650ca1bc6",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "ab2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1ad7ddbc-f784-4fde-9f26-08e1a2ada97e",
                                        "note": "ab",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "92f3aad2-7b3b-48d4-9d7e-20d4e8aadd73",
                                        "note": "c",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "28387332-a61f-4104-9b67-a6cde2e8c4ed",
                                        "note": "eb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "bdf648b0-44e7-4494-b291-75959b13644f",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb d5 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "531a6dae-59a5-4ad4-a505-40bf236126c9",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "026b91b8-f66d-4bf0-b7c5-c6d7ca8c57db",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ee77d300-f8b2-4abc-b298-d82393a74e2f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "27014545-b60d-4b86-9789-e264d8e70ad1",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b22ee753-d433-4aeb-9c8b-b8cb7fca929d",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4cd58f1f-9da6-4d2a-bc23-24cea9d13d83",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a7a6797c-7231-48f4-a1c4-60fa9a17dceb",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "614f426d-e570-41ed-84ec-012aa1fea66b",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "08371e86-e7c9-4f8d-bc24-639f9c6f26a5",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "cc06f5d5-dc34-4e57-b3dd-4608e1ac4f5d",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5323a680-a6ca-44bb-a9b3-0145c64a9b3a",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1641995a-c06e-4020-b536-a1c4810389ad",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "2836d216-f3c0-46e9-85fb-5efd9c25510f",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "c5/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6e032da8-070a-4f8f-b442-f9847d639d62",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5cf5e52c-06b4-4a17-bd00-d175ebda9af5",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "eb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2da6aad1-f8ea-4fa2-827f-9014fcbab240",
                                        "note": "eb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7b88a3b0-9853-41a8-b56f-823afec42cd9",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dee9da4f-0ef9-452b-a307-f4d30dfb5c76",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "223e2242-4c71-4a07-b256-03871167e4bd",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "75210b4f-f38e-4eec-a2ad-ba87228d01e1",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b8818010-7772-49b0-9c3a-963982363409",
                                        "note": "g",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "be69fba9-ae2a-4082-b062-390783b04504",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f42a0139-c8bb-43e2-8c68-73bf1323e773",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "efd64646-e08e-4a7d-a3f8-0b1f78ecc550",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5cc4c048-ca4b-4f76-a49e-7f7beed918ef",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2e48f7a1-7bbc-4816-a7f3-a8f5b18ace00",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb1 f2 bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9cba4f81-dceb-4349-beaf-d10598ab5e6f",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "de66be08-8905-4852-9848-0e3ce00a2aeb",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "abc98db3-b801-4ee8-b46d-bd4413a674a4",
                                        "note": "bb",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb1 f2 bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "03a3f22d-beec-4bad-b075-427293bfe32d",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bd459d49-a902-45eb-b126-b5ffb605cb2a",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "3e17dd9d-6672-4d22-8a5e-e3f89196afa5",
                                        "note": "bb",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ],
        [
            "Кульминация",
            {
                "name": "Часть 8",
                "index": 7,
                "bars": [
                    {
                        "id": "18387d59-22d0-4bf2-ac16-e4db38fd1c8f",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "a a5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2defd311-2735-4093-b69e-ab2a99bfd5b3",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b5ab3ae8-fc92-4795-9070-8a3f006ce2c3",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4ca1960d-69ab-4b50-ae2e-3c5c88ed5e38",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9355c3e5-db16-4a50-9fa7-51125e4f7084",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a756bd48-3cb6-4c82-b3a0-30314716cf95",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a a5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7cf7ffe7-845d-4c30-ad1e-01d53410879c",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a26e6aa9-0543-44d4-a4d1-5ded93a47137",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c6858fcb-c052-4d5e-8b49-605b6b9bcb55",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7864a824-c1a0-4ae6-bb4a-1ddb8ad3ad2e",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "790ca6c9-9a89-49d9-a4ef-0ad9e192c03e",
                                        "note": "a",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "38ed9359-b8a1-4d56-856a-b05fddc54f70",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "61f8a02e-ced9-4a44-8b16-b340f7c68859",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d4",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "cd551d1c-4238-44c8-bf03-883cf50d006b",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fd55317b-60bb-4801-a3bb-490129518bf1",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "5b107807-7179-4e1d-bc0a-61f30b4ec16c",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g# g#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "89d83e8d-4e01-4717-8381-44ca547429f5",
                                        "note": "g#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7814745c-52b7-408a-81b6-de33ce9fa3d7",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2d9dabf3-5554-47fb-8689-032eb1a8f658",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3cba7ce3-1f61-4def-b91d-caef6afb2c5a",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e274f6d6-f986-468c-9941-3b7495e242fa",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g# g#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33873eef-737c-413b-91bf-c7f281ee130c",
                                        "note": "g#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "8b357493-e43e-4864-a626-b2af4c362152",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "45b8152f-615e-4e5b-88c5-c6c56503183a",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1e5577a8-f690-48db-ba67-43382221fe96",
                                        "note": "c#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "927e16c7-40d7-40f6-b8cb-80bad4e86ce5",
                                        "note": "g#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9dd366ff-68af-4d2b-885c-3ce26a1bc4bb",
                                        "note": "c#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "46392b48-8b64-4c1f-939e-6bbe81718ece",
                                        "note": "g#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "07830ba6-7c9a-4003-9e0a-758e965aed09",
                                        "note": "c#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "2babcb49-a0a6-493c-80a9-338e45a92106",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a66f33a3-8f84-4c82-91ca-9f0bced142a3",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "a362eb8c-297d-44c9-8ffc-9dbb8c432c95",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d8c4a9d7-0d0a-4684-aef8-0ed7b70dc11c",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1b77247e-9027-45a0-b9fb-7c85d47f45b9",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "10540ad7-d576-4bd9-bdb1-013df9356e8e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f f5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f27b791c-7f49-43ca-936d-6faf4940d0b4",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ee1e6c3a-1bb3-4172-ab9f-4c1cfc994feb",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "cd050cdc-0515-4f04-8a75-34cd698df8be",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b0d11053-b15f-452f-9d73-30d933587638",
                                        "note": "bb",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2a9025f0-8d0a-4974-9ea9-3f61dfba59ad",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "870587ae-b342-4ef1-89b6-3aa2017ca927",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "db",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dc955675-c2c2-4664-ac89-e12ef516f269",
                                        "note": "db",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4ea50d72-f558-4d5f-8252-cf346b831f22",
                                        "note": "bb",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6dd1d35d-f2ed-49aa-914a-b6287ea342f9",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "551b18a2-40a6-44cf-a990-c568210352ae",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f# f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "246adbbb-7ad5-43e1-8915-bca6cbba4003",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7fc5ec73-698b-4051-be7b-2f5404610eff",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "71cfa75f-9a43-4f0e-8b31-5daae687d96c",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "282b52bb-da98-4e74-a04a-e20d394d26a9",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a0704a0c-37cb-47fe-8246-8eb4be4ece08",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f# f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9157058b-46cb-4abd-b640-35bfc57543f2",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "25726dbb-3a89-4c0a-a4a6-9fae0671558e",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f98d4fc2-8e69-4ff8-926e-0e930e993f2b",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e02c708d-29c6-4b9a-8e55-4fdc41fd89fc",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6fffb45-668b-4299-881a-371ac898e03e",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "66f74194-5748-411b-a7b5-4fae668f87d1",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3472195e-75d9-4825-91b3-778dd7d41c52",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "eaae71f0-9173-43c9-aa11-4b7b1ec2228d",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "df6bd3c0-742f-4a44-9570-8fed187accf2",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "a5/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "89938ea6-c4ef-4dea-a9a9-b62a38e36e49",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7587f09d-c53a-4700-a427-7fa246a56e6c",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "19df8290-e8fe-4519-a319-649314b7a071",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e25c0157-36ac-421c-adb0-31248c003687",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a5/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8c7ece74-7118-44a5-9bf8-284a798a992f",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4946fce3-ad71-46bc-92fa-c1f3b4fcaaf1",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5c21146f-1380-4cf0-a02b-e5cd4ec24675",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "528b37cc-384e-4fc8-a8e5-3ca3cba85c26",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a5/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ea9da146-dbf1-4734-9490-328a04c95233",
                                        "note": "a",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bfac15c1-8de5-44ec-a498-31d69b04fb2b",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a/d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e16bf783-0971-445d-b477-767cf0b6bb93",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "79c7137a-6afc-4751-8bbb-d4e121a24f02",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "d2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7864a824-c1a0-4ae6-bb4a-1ddb8ad3ad2e",
                                        "note": "d",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8f130360-73c3-4ea3-a465-ede1db9be6a2",
                                        "note": "a",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2cc9953d-18c8-4839-aa96-f1cf6d5f48e1",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9dd9392b-fb95-42e4-a1ed-c44703a986bd",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "a",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bb84daff-e81c-4925-a823-5e52d84153a0",
                                        "note": "a",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "ecce457b-ff37-4450-98cd-adaeb9fcd882",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "g#5/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3a233bb2-f8d2-4aaa-8f82-e7c707bee645",
                                        "note": "g#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f681aa98-f7b1-4a17-a8c8-7b4321ca02c1",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "77d1bb1c-cdf5-4046-8923-716c8de33374",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bea55db4-fe93-4317-881f-3eea14c9f629",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#5/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "09a0f3d3-7124-43ea-ac68-23f5da1bbdfd",
                                        "note": "g#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "2d26e20c-488a-479b-8d38-f5cf628b0a04",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d2495bd1-8f19-4dc4-bc1c-96169b73a39c",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "7fb5d835-dd99-49b0-b921-0abb831040a4",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#5/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "876afd59-6a78-4348-8ab7-72510a1b9f4e",
                                        "note": "g#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b00e835e-6f06-4665-a8a0-ed8eeab79c65",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#/c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "94864e12-4b7b-4400-b991-07cac243a208",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "81fe1aa6-be0b-43fc-82af-42c191c2989c",
                                        "note": "g#",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "c#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1e5577a8-f690-48db-ba67-43382221fe96",
                                        "note": "c#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "927e16c7-40d7-40f6-b8cb-80bad4e86ce5",
                                        "note": "g#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9dd366ff-68af-4d2b-885c-3ce26a1bc4bb",
                                        "note": "c#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "g#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "46392b48-8b64-4c1f-939e-6bbe81718ece",
                                        "note": "g#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "c#",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "84ba9336-759b-4e2d-9ba8-3475b7bb0b60",
                                        "note": "c#",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "07321f2d-a894-4ff8-beb7-7d3b7c563e84",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f5/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "84460013-af58-4243-9e6d-a9e752a7e735",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "94350277-9c70-46fc-93e6-0bcbdcf6163e",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ac2bf09a-1b53-46d3-9220-76ce1327c7a9",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "019ff47d-1c51-47a0-a1fd-be39b24ad1a0",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "61a56b64-7385-43b7-a895-5a953d2d44b9",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1458cd74-d85c-4289-966f-6cf96709f745",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e66d5b71-6ce4-453f-b0d5-d97bcea1b17f",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "95c4968e-d44a-4563-8fec-e0e4f74fd1be",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f5/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "86eb18d7-c4b1-44bd-be41-b429f90592eb",
                                        "note": "f",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5136102f-62b9-47d6-94c0-c68a58c0fa2c",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f/bb",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "86a30a9a-8a0e-443c-8ba9-9b902067c1a7",
                                        "note": "bb",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "03443e07-3903-4975-931e-874d21b1424b",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "bb1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b0d11053-b15f-452f-9d73-30d933587638",
                                        "note": "bb",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "f2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0c0ffd5b-a0ef-4f61-b9bb-17edc06f8458",
                                        "note": "f",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "bb2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b4416b9e-bbeb-47b0-a6c9-e19db22cde94",
                                        "note": "bb",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "db",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1a322daf-c480-4b72-ba29-2f3839af3a0a",
                                        "note": "db",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6dd1d35d-f2ed-49aa-914a-b6287ea342f9",
                                        "note": "f",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    },
                    {
                        "id": "924b5135-97f5-40b8-a819-c2d263aff6f4",
                        "triplets": [],
                        "defaultOctaveLeft": 3,
                        "defaultOctaveRight": 4,
                        "right": [
                            {
                                "originalText": "f#5/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6f24f0eb-f79b-442b-bdfb-fa9094c12c16",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "821235f9-5e92-48fa-9ae3-6427f3b9a0a4",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "eda9db30-494b-42c8-96c6-36945914b55f",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bc25a2f1-c706-44d4-93f9-c9545bca6728",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#5/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "108a2673-06ac-424a-b19a-4e47b4d5f8be",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4a0367d7-f96e-4633-a947-848308983b9c",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c2c2470d-e507-442a-9724-2bf19994a5b7",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "405596d4-fa3b-47a7-9bf7-17f23632cb89",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#5/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4299c94a-cc94-495a-9417-f7276096b2fa",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c1e700cd-c907-45a3-8e6c-cea586545d50",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f/b",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "80f2c46a-3081-44ae-b29a-e4038c7a864b",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "78337f18-7377-4810-a90d-d54eb1b030e4",
                                        "note": "f",
                                        "octave": 4,
                                        "duration": 0.5,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "64acaf4a-4075-471f-875f-c7f061faf42b",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular"
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6fffb45-668b-4299-881a-371ac898e03e",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "66f74194-5748-411b-a7b5-4fae668f87d1",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "d",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3472195e-75d9-4825-91b3-778dd7d41c52",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "eaae71f0-9173-43c9-aa11-4b7b1ec2228d",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular"
                            }
                        ],
                        "size": 6
                    }
                ]
            }
        ]
    ]
}
