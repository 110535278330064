// [jQuery.position() equivalent is wrong](https://github.com/HubSpot/youmightnotneedjquery/issues/172)
function getTopPosition(el: Element) {
    const {top} = el.getBoundingClientRect();
    const {marginTop} = window.getComputedStyle(el);
    return top - parseInt(marginTop, 10);
}

// [How to detect CSS flex wrap event](https://stackoverflow.com/q/40012428)
export function detectWrappedElements(
    rootElement: HTMLElement,
    wrapChildrenClassName: string
) {
    // For each child of .wrap-children
    //   - find its previous sibling
    //   - check if its previous sibling is at the same top position
    //     - if not, add .next-is-wrapped
    //     - if same position, remove .next-is-wrapped

    // [...HTMLCollection] vs Array.from(HTMLCollection): the latter doesn't need downlevelIteration with IE
    try {
        const parents = Array.from(rootElement.getElementsByClassName(wrapChildrenClassName));
        const tops: number[] = []
        parents.forEach(parent => {
            const {children} = parent;
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                tops.push(getTopPosition(child))
            }

        });
        return tops;
    }
    catch (e)
    {
        console.log(e)
        return [0]
    }


    // IE does not support toggle() second argument
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/classList#Browser_compatibility
    //rootEl.classList.toggle(hasChildWrappedClassName, containsChildNextIsWrapped);
}
