import {INote} from "../skeleton-entities-data/note-data";
import {SubtitleLevels} from "./subtitle-levels";
import {HandType} from "../skeleton-entities-data/skeleton-data";


export class LineInfo {

    constructor(private totalLines: number) {
        this.lineInfoLeft = new Map<number, SubtitleLevels>();
        this.lineInfoRight = new Map<number, SubtitleLevels>();
        for (let i = 0; i < this.totalLines; i++) {
            this.lineInfoLeft.set(i, new SubtitleLevels(HandType.LEFT))
            this.lineInfoRight.set(i, new SubtitleLevels(HandType.RIGHT))
        }
    }

    private lineInfoLeft: Map<number, SubtitleLevels>;
    private lineInfoRight: Map<number, SubtitleLevels>;

    public addNotes(notes: INote[], line: number, hand: HandType) {
        const subtitleLevel = this.getLineInfo(line, hand);
        subtitleLevel.addNotes(notes);
    }

    public getTotalNumberOfLevels(line: number, hand: HandType) {
        const lineSubtitleLevels = this.getLineInfo(line, hand);
        return lineSubtitleLevels.getTotalNumberOfLevels();
    }


    public getIndexAndOffsetForNote(note: INote, line: number, hand: HandType) {
        const lineSubtitleLevels = this.getLineInfo(line, hand);
        return lineSubtitleLevels.getIndexAndOffsetForNote(note);
    }

    private getLineInfo(lineNumber: number, hand: HandType): SubtitleLevels {

        if (lineNumber >= this.totalLines) {
            this.lineInfoLeft.set(lineNumber, new SubtitleLevels(HandType.LEFT))
            this.lineInfoRight.set(lineNumber, new SubtitleLevels(HandType.RIGHT))
        }
        return hand === HandType.LEFT ? this.lineInfoLeft.get(lineNumber)! : this.lineInfoRight.get(lineNumber)!
    }
}
