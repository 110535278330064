export const VeterScheme = {
    "settings": {
        "quadratSize": 8,
        "octaveNotation": {
            "name": "Научная",
            "entries": [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7"
            ],
            "defaultOctaveLeft": "3",
            "defaultOctaveRight": "4",
            "regexpKeys": "0-9",
            "regexp": {}
        }
    },
    "data": [
        [
            "Интро",
            {
                "barsize": 8,
                "name": "Интро",
                "index": 0,
                "bars": [
                    {
                        "size": 8,
                        "id": "409dccc1-7ec9-41d7-9dcd-8f198ae55743",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "c4 c5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "885c2134-e0a5-430b-a40d-7cb2081c3c5d",
                                        "note": "c",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d130bf0a-e9f2-4b2a-a752-1a0b4d381443",
                                        "note": "c",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "23b5b7a8-dae6-4a77-bac6-8fd1a5bc9e1b",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "09cfb9bf-a325-4fc4-ac6b-eb7bcaadb614",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "eb4263da-e023-4d7b-bc79-84a27fa9d0b4",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "35a7f28e-cf02-49ba-9d7f-4ef8aa1af9b0",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0ac78612-2029-4970-b1ef-e7c07068fa5e",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "76c4f702-0f16-4e65-8d8a-30b85af2ae9f",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "af9f316f-889f-440c-aabf-34d14d290b0e",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "1725a897-4310-4ce6-a102-f8188292a2b7",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e1941f2a-0de0-47fc-b95a-d0525348e2b6",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c9af60b7-323c-4800-99a9-c9260dbee720",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "36e2b8c8-4e9a-4b97-aa85-833f35e3d5a7",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "e4 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f14c0c3e-8ff3-4de7-a0fb-8c09aff8caaf",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "78d93b1d-15fd-4429-9c08-55031b65741d",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9068e678-06a8-4a97-8b93-d62c3399155f",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "14d732f1-5c1a-4828-8e9d-0a8cd7b663e1",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e5 e6",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2a75557c-1a5b-4bf7-a3dc-b93d90cfcec6",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "cb865862-b7a0-45d9-ab26-1a664ae25f32",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 6,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0e4c348d-d112-44de-9436-e30376d61db6",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "01036d27-6bb3-4c68-a826-67af020f23c0",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "53e823a9-2988-45a4-9ca6-47ad3c78c5bc",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "279a1677-54f4-4c8a-8de5-07e1460d864a",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "943187c2-53d6-416f-9773-82d04739ade4",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "7397159b-be8a-4301-94c6-645923a85742",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "de672b5e-4c0a-4811-82bb-e742ad933309",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bf173b4f-377a-4c94-a61e-4480601d57ba",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1dee3d26-1a8a-4fe6-955d-183d96173167",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0bafd302-820d-413b-bee0-bc9bc0ba7183",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "20445a84-bf7a-49ed-8ffc-d553847a55be",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "53499a44-f03d-44bf-a728-c9e983ef3b27",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e5e9e1d-5d53-4caf-b3b7-920b041670b3",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dbbdf72e-3590-4faf-9989-7228041b1997",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b358daab-703c-4ee3-b160-d4013f537adb",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "d1a2baa9-965f-45bd-ac3e-f7d122bb549e",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d6227185-4f81-4de4-90f5-36daebae25d4",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d07e4d69-9b26-4265-8ace-c4a06329bdfa",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "abacd86e-753d-4b1f-ac27-d8d368c3c32b",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28a136aa-be4d-4e67-ad90-046b518cfa3d",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "19cc2e89-0037-46fe-8cca-7d8243bb33ef",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#5 c#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4007ddde-0362-4d38-8e9b-2c8e154b7287",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d014216f-1c16-4c72-a358-f76b748b94f0",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "19d34b74-a278-4d93-b06b-088cdec144ba",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c8a25841-dad1-4b2b-90c4-ac2f4698dde0",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6d3d7df2-4dfb-43a0-9a05-77b36b43f570",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "daf3ec8b-446d-43c5-8de6-2c01ee46cdc8",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33fd08e3-4828-4c15-b356-4a8ee1450edf",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "2c8fc610-1b6b-4498-b1e6-633a4195a6ba",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "07d4345d-2c78-4f67-9355-965d404156f4",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "063988ef-c5a8-407e-bf1b-85a3876edd2b",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d7896db4-14de-43fa-ab40-befe10724de1",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8aed5edd-b81e-44ca-8c73-95a79e0f06f9",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "baf5431b-ac6c-490f-9626-cb76c5fd392e",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f94717e-7a2e-4106-8e8c-321195456980",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "518624dd-9701-4989-968b-928ce303a391",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "db828bf5-c6e8-4b65-b78a-ccf5a449e5ec",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "e5 e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ce52da3a-4f18-46f6-a7b1-f0133d48d87b",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "da3d5797-496f-475b-8e71-de8dcd7cac6e",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "529b69cb-8c7d-44ab-85c3-bd0cb855487e",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "45f070a5-3419-416b-8942-1749fee97b0e",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "546608ba-940d-4de4-9401-2d06fc08b8b7",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g4 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "37262fec-597f-4942-b260-c414b550db6a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c5bbb44b-40d6-441a-9c72-093e30f179a4",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9cf65a3c-6f4b-4d20-935f-d43b6a14ec40",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "875e63e1-6027-4e7c-a8bb-9d032bdb0496",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3c3600dc-1eb5-4d65-8ddc-d09d37ea6409",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "637a31a9-71e6-40ab-a873-a413f221f0bb",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f54fb7e1-d52b-472f-9494-613092d79aad",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e73dc58-4245-48a4-999e-bc0b34e262d6",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2606aabc-fa6b-4734-bc45-c9bdaeaed93d",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e114ae0e-619b-43f6-9a32-2f54e6ca0515",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c40bfa0d-7a17-40fb-aee1-b86697c7f6c2",
                                        "note": "a",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9f9a6717-3d9a-423a-b7d4-e7d1caff03f4",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "94e7699b-1deb-45a8-a37e-2f8abb9b0ddf",
                                        "note": "a",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "78fa515a-fee6-4960-a379-ecc8648fdc21",
                                        "note": "a",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "fa9ee6ba-0ae4-4116-aaf4-6f0d3e91f54f",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a79c6cd5-ab92-46bf-984e-1cc9a488b477",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "381b730f-c9e2-4e15-9876-158a79ef1597",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1c612260-6616-400f-a1e2-a4a1ecec3412",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28a136aa-be4d-4e67-ad90-046b518cfa3d",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "19cc2e89-0037-46fe-8cca-7d8243bb33ef",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#5 c#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4007ddde-0362-4d38-8e9b-2c8e154b7287",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d014216f-1c16-4c72-a358-f76b748b94f0",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "19d34b74-a278-4d93-b06b-088cdec144ba",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c8a25841-dad1-4b2b-90c4-ac2f4698dde0",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6d3d7df2-4dfb-43a0-9a05-77b36b43f570",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "daf3ec8b-446d-43c5-8de6-2c01ee46cdc8",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33fd08e3-4828-4c15-b356-4a8ee1450edf",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "2c8fc610-1b6b-4498-b1e6-633a4195a6ba",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "07d4345d-2c78-4f67-9355-965d404156f4",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "063988ef-c5a8-407e-bf1b-85a3876edd2b",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d7896db4-14de-43fa-ab40-befe10724de1",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8aed5edd-b81e-44ca-8c73-95a79e0f06f9",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "baf5431b-ac6c-490f-9626-cb76c5fd392e",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f94717e-7a2e-4106-8e8c-321195456980",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "518624dd-9701-4989-968b-928ce303a391",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "b133636c-54db-46b1-b780-07dc2f7d472e",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "e4 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f14c0c3e-8ff3-4de7-a0fb-8c09aff8caaf",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "78d93b1d-15fd-4429-9c08-55031b65741d",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2cf8b811-c0af-4b65-80eb-b42536cc2320",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e4a1a05c-40d3-4949-bebb-c946b814ba3f",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "15f1d1e8-d5ae-417d-b856-4d26e11c0967",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "2a75557c-1a5b-4bf7-a3dc-b93d90cfcec6",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0e4c348d-d112-44de-9436-e30376d61db6",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "01036d27-6bb3-4c68-a826-67af020f23c0",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "53e823a9-2988-45a4-9ca6-47ad3c78c5bc",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "279a1677-54f4-4c8a-8de5-07e1460d864a",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "943187c2-53d6-416f-9773-82d04739ade4",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "7397159b-be8a-4301-94c6-645923a85742",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f4e161c3-1634-4531-af7c-f81989665722",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "16f497af-08a9-42b8-a9be-0f93b02fd7b8",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1dee3d26-1a8a-4fe6-955d-183d96173167",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0bafd302-820d-413b-bee0-bc9bc0ba7183",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "20445a84-bf7a-49ed-8ffc-d553847a55be",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "53499a44-f03d-44bf-a728-c9e983ef3b27",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e5e9e1d-5d53-4caf-b3b7-920b041670b3",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "dbbdf72e-3590-4faf-9989-7228041b1997",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b358daab-703c-4ee3-b160-d4013f537adb",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "115e5086-ef27-48e2-8029-4cbe684cb926",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d6227185-4f81-4de4-90f5-36daebae25d4",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d07e4d69-9b26-4265-8ace-c4a06329bdfa",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "abacd86e-753d-4b1f-ac27-d8d368c3c32b",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "28a136aa-be4d-4e67-ad90-046b518cfa3d",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "19cc2e89-0037-46fe-8cca-7d8243bb33ef",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#5 c#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4007ddde-0362-4d38-8e9b-2c8e154b7287",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "d014216f-1c16-4c72-a358-f76b748b94f0",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "19d34b74-a278-4d93-b06b-088cdec144ba",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c8a25841-dad1-4b2b-90c4-ac2f4698dde0",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6d3d7df2-4dfb-43a0-9a05-77b36b43f570",
                                        "note": "c#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "daf3ec8b-446d-43c5-8de6-2c01ee46cdc8",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "33fd08e3-4828-4c15-b356-4a8ee1450edf",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "2c8fc610-1b6b-4498-b1e6-633a4195a6ba",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "07d4345d-2c78-4f67-9355-965d404156f4",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "063988ef-c5a8-407e-bf1b-85a3876edd2b",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d7896db4-14de-43fa-ab40-befe10724de1",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8aed5edd-b81e-44ca-8c73-95a79e0f06f9",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "baf5431b-ac6c-490f-9626-cb76c5fd392e",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f94717e-7a2e-4106-8e8c-321195456980",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "518624dd-9701-4989-968b-928ce303a391",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "c76a92c8-f682-49d9-b199-1e82440a0a4a",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "e5 e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ce52da3a-4f18-46f6-a7b1-f0133d48d87b",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "da3d5797-496f-475b-8e71-de8dcd7cac6e",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "529b69cb-8c7d-44ab-85c3-bd0cb855487e",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "45f070a5-3419-416b-8942-1749fee97b0e",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "546608ba-940d-4de4-9401-2d06fc08b8b7",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g4 g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "37262fec-597f-4942-b260-c414b550db6a",
                                        "note": "g",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "c5bbb44b-40d6-441a-9c72-093e30f179a4",
                                        "note": "g",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "9cf65a3c-6f4b-4d20-935f-d43b6a14ec40",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "875e63e1-6027-4e7c-a8bb-9d032bdb0496",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3c3600dc-1eb5-4d65-8ddc-d09d37ea6409",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "637a31a9-71e6-40ab-a873-a413f221f0bb",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f54fb7e1-d52b-472f-9494-613092d79aad",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4e73dc58-4245-48a4-999e-bc0b34e262d6",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2606aabc-fa6b-4734-bc45-c9bdaeaed93d",
                                        "note": "g",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e114ae0e-619b-43f6-9a32-2f54e6ca0515",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c40bfa0d-7a17-40fb-aee1-b86697c7f6c2",
                                        "note": "a",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9f9a6717-3d9a-423a-b7d4-e7d1caff03f4",
                                        "note": "e",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "94e7699b-1deb-45a8-a37e-2f8abb9b0ddf",
                                        "note": "a",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "78fa515a-fee6-4960-a379-ecc8648fdc21",
                                        "note": "a",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "45741330-7122-4274-b2ef-0f35952b3311",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7ed82966-9491-4394-8272-fc8b24e1d25e",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "419cd371-b33e-4c74-ab59-2127bbb015e7",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c4100ffd-cb3a-460d-981f-455e8ebe8f88",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d0c65699-83f4-455d-841e-f6aa6aeade82",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    },
                                    {
                                        "id": "e436e16c-1fb6-47c2-a985-c89c37c9aabf",
                                        "note": "f#",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0909054b-8dbf-4d28-bc7a-cd536fd70443",
                                        "note": "b",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "07d4345d-2c78-4f67-9355-965d404156f4",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "063988ef-c5a8-407e-bf1b-85a3876edd2b",
                                        "note": "f#",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d7896db4-14de-43fa-ab40-befe10724de1",
                                        "note": "b",
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8aed5edd-b81e-44ca-8c73-95a79e0f06f9",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "baf5431b-ac6c-490f-9626-cb76c5fd392e",
                                        "note": "b",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6f94717e-7a2e-4106-8e8c-321195456980",
                                        "note": "d",
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": false
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Куплет 1",
            {
                "barsize": 8,
                "name": "Куплет 1",
                "index": 1,
                "bars": [
                    {
                        "size": 8,
                        "id": "4c44336b-d3e0-46ec-8ee8-1fb8c31d3479",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Проигрыш",
            {
                "barsize": 8,
                "name": "Проигрыш",
                "index": 2,
                "bars": [
                    {
                        "size": 8,
                        "id": "4de4037f-2d67-4ad9-a61f-489de14ad928",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Куплет 2",
            {
                "barsize": 8,
                "name": "Куплет 2",
                "index": 3,
                "bars": [
                    {
                        "size": 8,
                        "id": "5863a420-2968-4e4e-a6c3-d3150ddcbe4e",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Припев 1",
            {
                "barsize": 8,
                "name": "Припев 1",
                "index": 4,
                "bars": [
                    {
                        "size": 8,
                        "id": "cc622e42-e078-46bf-9270-ab01f24759c1",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Припев 1-1",
            {
                "barsize": 8,
                "name": "Припев 1-1",
                "index": 0,
                "parentName": "Припев 1",
                "bars": [
                    {
                        "size": 8,
                        "id": "ec345d6a-5192-4d85-a241-5f1d94ccdeb7",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3bc7cb3b-65f3-42ed-9f89-81c7e6888dcb",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "2368248f-ede0-4e10-9bc1-079801a13d40",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "639b2221-bbdd-42c9-ab60-6a4fcb7cec8a",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b2c9619d-293c-4f49-bc11-da27ca037c53",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "d57a0858-5c22-4517-9f14-a20bbc70df5b",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 e4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "32d4b7e4-f7f1-463c-bf09-ea6bebcf29b1",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "887ff430-f8c3-4d60-a1c1-0bf7ff3a16d9",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "650c410c-b415-4b24-a7cd-b113a2a86736",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2a3dd230-69ce-43b4-9a0e-6ebb1aff150f",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "6d98ec67-ab17-4493-9103-daf65d9e935d",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 e4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "82420726-0d8d-49e4-92ed-ff217060b818",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "162167ac-7228-42eb-9a15-7085b8e4acfa",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ad202de4-ee9a-45c5-a926-f01f4da9717f",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "053bf57f-1c4c-4868-a9ab-827e68af0abd",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "29cc85d4-3b76-4006-8ad8-61d2b80f46f5",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a7008a0a-71eb-452d-bc6a-fa87bbd65974",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4f3c3fde-3639-4d31-aef1-26a52eda9848",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "26ee2fff-5220-4f5b-bebc-140e237d3581",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4c4afe94-a672-4dda-b879-5809e4ec6178",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "989fdac4-dd0c-416b-8062-a538421873cd",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6e8a56b7-5800-4cd2-b3c1-2bbaad9f2fb3",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "9cb6b272-ab8c-4b29-9ef1-c5ef762ae808",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b880c78c-c81c-4f61-83cb-ebfceb1924fe",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "e34c595d-afb0-4be8-a006-3a0c456c9afc",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 b4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a3c01528-925f-40c2-b373-e6b1b77ee1a4",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "780011c7-f932-4a92-ba52-ecbb3195a69d",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "60ec4a16-fa95-4c70-8841-dfeae9bbd6e4",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d575c0dc-3d9f-4802-b992-54388ebc0462",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "deba8013-20ac-41ee-aa0d-034f1155af8a",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 b4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c626618b-dc8c-4ded-b552-a8724f080909",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "030af89c-0fa4-45b8-bd13-5a2f9047c720",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "590b7b8f-b43d-40a6-8943-a4cb746efd07",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "2ef12336-3f2a-44e8-8bd7-d891204095f9",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "47d17594-cd42-4ab4-ace3-3044e1ab03b5",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5aaad35e-9163-4ae9-a97e-fd144eaeb938",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b61c97b0-5f5c-4e63-8a20-1e15bc6ccce2",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "a8c16b62-663b-4eda-ba63-8500c0b91eea",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c29e777c-9256-4be4-a054-b31fe11354f0",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "58bb0bc5-26aa-4b73-968b-1ebb40534039",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8e0c673e-c03e-4ae2-ac7f-c55c7f9f6aad",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "56bee02d-7256-4849-be1e-7dfe03ca6a18",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8e94f947-bd5a-42bd-b71a-18dd19c20ed5",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f2ab6507-c97e-465e-b7e0-4ba534f204c7",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "dd5bfc80-5a31-46b5-80fe-224899542e46",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 e4 c#5/c#4 e4 c#5/d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c8248a0a-20b2-4b89-a258-43e95e05d18a",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0d755975-dddb-4f10-a683-fe2695d462d0",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b85f2a8c-ef7e-4bc0-a1ba-e67b8cba9e7c",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "25c8030d-5b35-4515-b27e-20d17fc0a2df",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ef1cd8ca-3c20-4af0-97a6-a525ee2b67a1",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1b4919b4-c9a4-40bf-9ca4-ab1a51ee4cd0",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "152e229d-8af4-475a-9148-c17054455109",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "e724c281-7c8f-4f4e-83ae-9b54c8b0f91b",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c26f97e6-c9a9-48cc-95bd-cdec934baeea",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "581f9075-6050-4507-acc5-b0346c8eddc8",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bfbc22a7-d7d3-4063-8fc4-c71353f02606",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1a77744f-6b8b-4a07-bb52-1c4f2159e044",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4eafbffc-5ef1-43cc-82d7-205f0cfd34f4",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "414c0432-4d88-4740-9971-3974eb738958",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "9dbda507-e688-428c-85d2-e457d5d6babb",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5d2443e7-c838-4e2f-9494-d32f01c579aa",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f158ef4d-e177-47ff-a9a5-f0bb61ea603a",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b0fb32b8-c6ce-4def-9369-13cd2540b216",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d86e31c9-4ab1-479f-8d7f-da2b5b417741",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ff44f022-398b-4a7f-9e8d-56ea16de6455",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d365a2cf-5996-47e9-9fe4-df13663ee704",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 0.5,
                                        "playbackOffset": 0.5,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b3976021-517d-43ae-844f-0c0a782bfd6e",
                                        "note": "f#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 b4 f#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "b5b7f319-4ba6-4ef5-9337-79ee36e9c2d4",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "510bc6e2-b9c2-4a06-97d2-d4276197f1c9",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "78944a1b-129d-4eb1-b8bd-06f846a908ea",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "be798f1b-e5b6-4c35-a2fa-41d88f957517",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "08a0ce09-7016-4c74-adf2-d198dd8b1b78",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "01363f59-3bb5-4195-bca8-50a288e8e40f",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "76a14d24-6782-4ecd-aef7-3fd6dab7bbd7",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "0c5b8666-42f7-491c-865f-ffda5a60d446",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "f6b5c726-fce1-494d-aaa8-262fa6331bd6",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "509ed5c6-94ac-4e26-9bd6-32d4c79e11cf",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "82038db6-e75a-4802-8a77-4fce968edcd2",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "95a8a767-1804-4a77-ab81-0e9582420750",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "a1903ccb-718b-47bd-bb18-2efab2386d02",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2a3c8309-3a26-4e2c-a63d-840c55a68e46",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "6336f0d9-2e8d-4920-857d-0164f5274da1",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 e4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "16be00ae-55e0-4902-90b4-94099826e24d",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "619828c1-ee4f-4210-94e2-1e60cbe5897d",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c94df37c-697b-444e-be32-017f678c4877",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 e4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "05c3b994-2e06-45e5-9437-a99377ab2945",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "10ebfad8-ff46-44b9-a4c1-e80204de328d",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0208ed1b-faba-4769-bbcc-32c083facb15",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "dbfa1c9b-0895-4af3-839a-4b70e496ab66",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bb4b053d-d3f8-467d-a3b5-763687aca191",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "6092c5fc-b2e8-4385-9626-0d6819d7e19d",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "122a82ed-4ef0-4af5-9c54-21e8e63b941e",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "e0d0e5d4-7596-4a77-b601-780c8285a1db",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ba3a1f90-1088-4d45-9a6a-82f991ef6443",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "51a927a7-f42a-4be5-8a40-0ffe2d60c9fe",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4f0d2fd4-0046-4370-bcec-adec64a26068",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "f5881244-d95a-4bf1-947d-539905f6dfa9",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8783edaf-23e0-4781-a044-3b43623a50b5",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "fcdba72e-11e6-4f67-830d-022c3bacd26b",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "4c09507c-aad0-425a-a22f-a88ce36923b5",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "71452cf3-1199-46cb-8a9b-c62c1ecbe912",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 f#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "8a6121e4-967e-4673-bdb9-908a7d43acda",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "5f0d7fb2-8eac-4f11-9118-099d5b8bb954",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "691c42a9-9286-419a-a6d0-efb9d23e5874",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "668f195d-6d56-4bfa-8d5d-9559a08812b0",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ac999398-23d0-4aba-b97d-e29dc2f26175",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "26796e1c-5f4a-44c7-b72f-c9e8a1210f2c",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d0bb389e-6189-430c-8a5f-9e0f23405af1",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "aaf5f718-6eb9-4760-a2e6-d0cde8f75e9e",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6ddfbd4-d500-4af2-9bff-6a3d0d1d5e80",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "05c00c5d-7298-43aa-89f6-a64e144f44fd",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "73eb762d-28f8-4ce3-9c01-f69db3d56cec",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "f#2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "3a81d420-a67e-408a-836d-4a6be470680f",
                                        "note": "f#",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "ef1e0e04-ca85-4ae6-bbd8-22fb93d9ebe2",
                        "triplets": [
                            {
                                "start": 4,
                                "length": 4,
                                "hand": "right"
                            }
                        ],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "731afe41-b395-4b95-824a-e01c2437e029",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fa53a954-0b89-4199-af3d-9615543bd92f",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "429f64b9-5dbd-4914-b716-683e4362cd07",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "78332517-b5c4-4e32-bea8-404d86e535b4",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "90d94045-735c-42e2-8050-c0953362d1a7",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4 e5/e4 a4 e5/e4 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "ae4497b9-0add-40f2-8c43-0f1d6b2cd728",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "8a250e8f-1c6a-49c9-be2f-a40efabc5237",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "ee8b4204-36f7-4745-bd81-bb8ae5057bd0",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c29510e1-d4ab-4bb3-8a5c-185acd6148a5",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "b52e8f06-75d2-44ba-aafc-2b621b969a46",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "affcfb5f-cf5c-49b4-8910-b7199832f8ff",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 1.5,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c9d34e10-0db5-48f2-99bf-d0d69ea3b74b",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 3,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "96d52495-d560-4b24-b655-2df5d40944a2",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "02107308-8eba-43e4-85df-ae9198c97510",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "668b75aa-e697-4bae-9c06-5afbe8ab64ba",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "655d8d3c-0b09-447b-a6fe-0406e211c04c",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b8576309-eeba-4d7a-9620-c5107437c843",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "274b5aca-6fb2-44ce-b1c3-5fd2d7260978",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "5cf5209d-7b56-475d-a0b7-a48569a0f84f",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c50531bb-196a-4bf1-9205-799f81b21bef",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "4f2d3398-c9fa-45b2-9ead-799a8157af2d",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "c392a9f3-cd65-443e-aacf-8f8322306a0c",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5f261c46-9035-47fc-ab92-c6142bbb3469",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 g4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "90934c9f-3eeb-44c0-92e1-c9febda45877",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "c5bd18e4-f328-4a24-93bc-b69b86117054",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 e4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5e33f16c-6ceb-4836-a39b-c1a509f22d84",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    },
                                    {
                                        "id": "fdf06aed-3f0f-4313-af6d-c11baa66c88c",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "feather"
                                    }
                                ],
                                "isPresent": true,
                                "type": "feather",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "b172ff51-a1ac-4bab-b5ec-e62933a995e7",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "d703f18c-4129-4f3e-945e-7d6f5a9499e9",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "1f6fb8dd-91a4-4ab4-ba61-44a287529cc3",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "72abcffd-f42f-4030-b36e-7f21b773d934",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "88be4f23-af1f-40f2-8c4f-8706f633795e",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "9f2e195c-7df1-48f9-81c8-c246f7813d67",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ],
        [
            "Припев 1-2",
            {
                "barsize": 8,
                "name": "Припев 1-2",
                "index": 1,
                "parentName": "Припев 1",
                "bars": [
                    {
                        "size": 8,
                        "id": "484d95d1-519b-4780-a23c-eef3b8cabaa0",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "15947f34-7d42-4ca5-9f9f-89821219f25b",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "d2155ef6-f147-4b32-a3a7-b16e824c528f",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a4 c#5 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "98384461-af56-401b-a917-97b07fea68eb",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "75954329-a113-496f-a612-abc0656bb08f",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "c59af5aa-8e20-4e7a-b534-9f28958fb3ec",
                                        "note": "e",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b3 b4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "51c49cb9-9ad6-41b0-a70a-d51b74c1eadc",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "8a50d81f-fbde-41fe-981d-4dd7e0458ff0",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6f96223c-6367-4f30-ba58-cb6b5d617115",
                                        "note": "b",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "868cbe3e-58b6-4aa3-9234-76457336f7cc",
                                        "note": "b",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ac59d06e-51ca-4571-90be-67bb85110ca4",
                                        "note": "a",
                                        "displayOctave": true,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "4de5fc6a-c040-4196-bbec-9b530dfa30b7",
                                        "note": "a",
                                        "displayOctave": true,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "55ac174a-10f2-4c5e-bb9c-6c4de09fdc36",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "500a3eed-8764-47f0-ad47-f329fb90b046",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "7aaa8577-c7df-4cd4-b494-04d42b614151",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "37ec7070-c653-4885-9c2c-43b90aa80564",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "b4 b5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "1388e369-5cf0-44fe-af66-b923f08d9c62",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "196bba83-f719-4ea2-ac78-0a03fb7946fd",
                                        "note": "b",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a4 a5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "6adcc6cd-e787-4d2e-ae0c-625f35f703f0",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f04365e1-c693-45f9-a3a1-8eb51d1a8332",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "5aabb609-d89a-4837-8840-67b0d25f7c2e",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "1de8a22a-615d-4715-8b21-0f8bb6c55ae9",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "7263181d-eda8-43e1-8ca3-10f30b713986",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "22f50400-c3c3-4206-96a4-179c2766df29",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "cbd040e3-3cf9-4518-82b5-2ca1ca96f36a",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "c#4 c#5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "0f42a70b-7d3f-4048-93be-d6cf43b52a90",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "42485c16-21ac-433f-9236-21be012ecb4a",
                                        "note": "c#",
                                        "displayOctave": false,
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "g2 g1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "48a1b793-e3ca-4d52-9fde-459b8622a92c",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0d1a4012-5362-4c87-a1c2-6bf6ae2fdbc9",
                                        "note": "g",
                                        "displayOctave": true,
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "181f53a3-afa2-41d1-9711-1412c5b88d7c",
                                        "note": "g",
                                        "displayOctave": true,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c6cc1d98-903f-4726-bbfd-9fd856191635",
                                        "note": "g",
                                        "displayOctave": true,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "bf6d1fb8-7f47-4db3-bd90-43df278cf210",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "g3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "c1bb48fb-4766-4f8a-b723-4e0a22bef035",
                                        "note": "g",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "2fa6253e-684d-4278-9f91-1b2cf0b2c59d",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f9304590-eadc-4f31-83e1-ac306b025844",
                                        "note": "d",
                                        "displayOctave": false,
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4 a4 d5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "2eb18b91-6389-4f3a-8e1c-62b2e222a91b",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0f855f5d-e5cd-49d1-bff5-781dbda61e52",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "bf25e7b1-3303-4af1-9089-b1a78ee80e71",
                                        "note": "d",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "d4",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "3147f2f1-52b8-4712-81fa-580757e306be",
                                        "note": "d",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "e4 a4 c#5 e5",
                                "hand": "right",
                                "notes": [
                                    {
                                        "id": "f804b448-e35f-42f6-91c5-39d690a810c5",
                                        "note": "e",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "f88b05ae-5f2c-4643-a40d-a3157b8b3046",
                                        "note": "a",
                                        "octave": 4,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "36fb03b9-85dd-400d-bd78-c5ff9de912fd",
                                        "note": "c#",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    },
                                    {
                                        "id": "0b87ae8f-b076-4a89-8f9b-3f798553e74b",
                                        "note": "e",
                                        "octave": 5,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "5f824ed0-3351-40dd-a68f-d6428b84cedb",
                                        "note": "a",
                                        "displayOctave": true,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "fa5d37b6-4252-43e3-b008-33ce6a77a2ed",
                                        "note": "a",
                                        "displayOctave": true,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "01a07325-e983-4ba9-9b39-88d0c3d83db8",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a2",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "8315294a-1ce8-42e4-b63d-11682897e89a",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 2,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "a3",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "ffc044e5-c097-4a62-bb99-ea5041938f0a",
                                        "note": "a",
                                        "displayOctave": false,
                                        "octave": 3,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular"
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "left",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    },
                    {
                        "size": 8,
                        "id": "e80d9337-54fb-4f4f-81ae-d8a0180aefe4",
                        "triplets": [],
                        "right": [
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ],
                        "left": [
                            {
                                "originalText": "a1",
                                "hand": "left",
                                "notes": [
                                    {
                                        "id": "14852796-7a83-428b-a12c-32a58c44c2af",
                                        "note": "a",
                                        "octave": 1,
                                        "duration": 1,
                                        "playbackOffset": 0,
                                        "noteType": "regular",
                                        "displayOctave": true
                                    }
                                ],
                                "isPresent": true,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            },
                            {
                                "originalText": "",
                                "hand": "right",
                                "notes": [],
                                "isPresent": false,
                                "type": "regular",
                                "lyrics": ""
                            }
                        ]
                    }
                ]
            }
        ]
    ]
}
