import {INote} from "../skeleton-entities-data/note-data";
import {getMidiNumber} from "../../utils/playback-utils";

export class LevelTreeNode {

    private maxMidi:number;
    private minMidi:number;
    private midis: number[];


    constructor({min, max, midiNumbers}) {
        this.minMidi = min;
        this.maxMidi = max;
        this.midis = midiNumbers;
    }

    get max() {
        return this.maxMidi;
    }

    get min() {
        return this.minMidi;
    }

    get presentNotes(){
        return this.midis;
    }

    insertNote(note: INote): void {
        const noteMidi = getMidiNumber(note);
        this.midis.push(noteMidi)
        if (noteMidi < this.minMidi) {
            this.minMidi = noteMidi
        }
        if (noteMidi > this.maxMidi) {
            this.maxMidi = noteMidi
        }
    }

    public splitBy(note: INote): LevelTreeNode[] {

        const noteMidi = getMidiNumber(note);
        const leftMidis=this.midis.filter(midiNumber => midiNumber<noteMidi);
        const rightMidis = this.midis.filter(midiNumber => midiNumber>=noteMidi);
        return [new LevelTreeNode({min: this.minMidi, max: noteMidi - 1, midiNumbers:leftMidis}), new LevelTreeNode({
            min: noteMidi,
            max: this.maxMidi,
            midiNumbers:rightMidis
        })]
    }

    public containsNote(note: INote): boolean {
        const noteMidi = getMidiNumber(note);
        return noteMidi >= this.minMidi && noteMidi <= this.maxMidi;
    }

    public containsNotes(notes: INote[]): boolean {
        return notes.some(note => this.containsNote(note))
    }
}
