import {INote} from "../skeleton-entities-data/note-data";
import {groupBy} from "../../utils/js-utils";
import {OctaveLevelInfo} from "./octave-level-info";
import {LevelTreeNode} from "./level-tree-node";
import {compareByMidiNumbers, getMidiNumber} from "../../utils/playback-utils";
import {HandType} from "../skeleton-entities-data/skeleton-data";


export class SubtitleLevels {

    constructor(handType: HandType) {
        this.level = new OctaveLevelInfo(handType);
        this.displayCache = new Map<number, { index: number, midiOffset: number }>();
        this.ranges = []
    }

    private level: OctaveLevelInfo;
    private displayCache: Map<number, { index: number, midiOffset: number }>;
    private ranges: LevelTreeNode[]

    public addNotes(notes: INote[]) {

        const groupNotes = groupBy(notes, note => note.playbackOffset)

        groupNotes.forEach((noteGroup, playbackOffset) => {
            if (noteGroup.length > 1) {
                // const notesPerOctave = groupBy(noteGroup, note => note.octave);
                // noteGroup.forEach((notes, octave) => {
                const notes = noteGroup
                    console.log('====================')
                    console.log('processing', notes)
                    const processedNotes = new Array<INote>()
                    const notesToProcess = notes.sort(compareByMidiNumbers)
                    for (let i = 0; i < notes.length; i++) {
                        console.log('--------------------')
                        console.log("processedNotes",processedNotes)
                        const noteToInsert = notesToProcess[i];
                        const levelNode = this.level.getNodeForNote(noteToInsert)
                        const shouldBeSplitting = levelNode.containsNotes(processedNotes);
                        console.log("inserting note",noteToInsert.note + noteToInsert.octave)
                        console.log("levelNode",levelNode)
                        console.log("shouldBeSplitting",shouldBeSplitting, "levelNode", levelNode.min, levelNode.max, 'notemidi', getMidiNumber(noteToInsert))
                        processedNotes.push(noteToInsert)
                        this.level.insert(noteToInsert, shouldBeSplitting)

                    }
                // })
            } else {
                const note = noteGroup[0]
                const octave = note.octave;
                this.level.insert(note)
            }
        })
        this.ranges = this.recalculateRanges();
        this.displayCache = new Map()
    }

    public getTotalNumberOfLevels() {
        return this.ranges.length;
    }


    public getIndexAndOffsetForNote(note: INote) {
        let index;
        let midiOffset;
        const noteMidi = getMidiNumber(note);
        if (this.displayCache.has(noteMidi)) {
            return this.displayCache.get(noteMidi)!;
        }

        for (let i = 0; i < this.ranges.length; i++) {
            const range = this.ranges[i];
            if (range.containsNote(note)) {
                index = i;
                const minAvailableMidi = Math.min(...range.presentNotes)
                const maxAvailableMidi = Math.max(...range.presentNotes)
                const averageMidiValue = minAvailableMidi + (maxAvailableMidi - minAvailableMidi) / 2;
                midiOffset = averageMidiValue - getMidiNumber(note)
                break;
            }
        }
        this.displayCache.set(noteMidi, {index, midiOffset})
        // console.log('retuyning from calculation', note.note+note.octave, index)
        return {index, midiOffset}
    }

    private recalculateRanges = (): LevelTreeNode[] => {
        return this.level.ranges.sort((a, b) => b.min - a.min)
    }
}
