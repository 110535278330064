import React, {useState} from "react";
import {SettingContextData} from "../../model/context-data-models/settings-context-data";
import {HandType} from "../../model/skeleton-entities-data/skeleton-data";
import {OctaveNotation, Octaves} from "../../model/skeleton-entities-data/octave-data";
import {PlaybackContextData} from "../../model/context-data-models/playback-context-data";

const defaultPlaybackState: PlaybackContextData = {
    currentActiveNodeId: -1
}


export const PlaybackContext = React.createContext({
    playbackState: defaultPlaybackState,
    updatePlaybackState: (value, cb?) => {
    },

});

export const PlaybackContextProvider = (props: any) => {
    const [playbackState, setPlaybackState] = useState<PlaybackContextData>(defaultPlaybackState);

    return (
        <PlaybackContext.Provider
            value={{playbackState: playbackState, updatePlaybackState: setPlaybackState}}>
            {props.children}
        </PlaybackContext.Provider>
    )
}
