/** @jsx jsx */
import React, {useContext} from "react";
// import {css, jsx} from "@emotion/react/macro";
import {css, jsx} from "@emotion/react/macro";
import {INote, Note, NoteType, PlaybackDuration, PlaybackOffset} from "../../../model/skeleton-entities-data/note-data";
import {HandType} from "../../../model/skeleton-entities-data/skeleton-data";
import {SkeletonNodeData} from "../../../model/skeleton-entities-data/skeleton-node-data";
import {compareByMidiNumbers, isChord} from "../../../utils/playback-utils";
import {TripletHandlingProps} from "./skeleton";
import {ClickAwayListener, Typography} from "@mui/material";
import {getOctaveInRussianNotation, getOriginalText} from "../../../utils/skeleton-node-utils";
import {getTripletEffectiveParameters} from "../../../utils/triplet-utils";
import {SettingsContext} from "../../context/settings-context";
import {NoteEditPopupMenu} from "./popup-menus/note-edit-popup-menu";
import {getQuadratNodeDimension} from "../../../utils/rendering-utils";
import {BarContext} from "../../context/bar-context";
import {OctaveNotation} from "../../../model/skeleton-entities-data/octave-data";
import {LineInfo} from "../../../model/subtitle-render/line-info";

export interface SubtitleSettings {
    fontSize:number,
    isMasteringMode:boolean,
    octaveNotation:OctaveNotation
}

export interface NodeSubtitleProps {
    nodeData: SkeletonNodeData;
    setExternalNoteObject?: any;
    index?: number;
    lineNumber: number;
    handType?: HandType;
    chord?: INote[],
    setNotes: any;
    tripletProps?: TripletHandlingProps,
    settings: SubtitleSettings;
}

export interface NodeSubtitleItemProps {
    note:Note,
    hand:HandType,
    onUpdateNote:any,
    height:number,
    fontHeight:number,
    horizontalOffset:any,
    nodeType:NoteType,
    lyrics:string
    displayLyrics?:boolean,
    displayApplicature?:boolean,
    displayOctaves?:boolean

}

export const NodeSubtitleItem = React.memo(({note, hand, onUpdateNote, height, fontHeight, horizontalOffset, nodeType, lyrics}:NodeSubtitleItemProps) => {
    const {settings} = useContext(SettingsContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isHovered, setIsHovered] = React.useState<boolean>(false);

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const transformFlatSign = (note: INote) => {
        return note.note.length > 1 && note.note.endsWith('b') ?
            {
                note: note.note.substr(0, note.note.length - 1),
                isFlat: true
            } :
            {
                note: note.note,
                isFlat: false
            }
    }

    return (
        <ClickAwayListener onClickAway={handlePopoverClose}>
            <div>
                {hand === HandType.RIGHT && lyrics && settings.displayLyrics &&
                <div style={{position: "absolute", top: -10, left: 5, color: "#8b0218"}}>
                    <Typography fontSize="small">{lyrics}</Typography>
                </div>}

                <div
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    css={{
                        overflow: "wrap",
                        display: "inline-block",
                        position: "absolute",
                        height: `${fontHeight * 0.7}px`,
                        top: height,
                        fontFamily: "serif",
                        fontSize: `${fontHeight}px`,
                        fontWeight: "bold",
                        cursor: "default",
                        border: isHovered ? "solid 1px black" : "none",
                        zIndex:10,
                        ...horizontalOffset
                    }}
                    onClick={handleClick}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                        {note.displayOctave && <sup css={{
                            fontSize: fontHeight * 0.6,
                            color: "#6F2DA8",
                            zIndex: 100,
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: hand === HandType.RIGHT ? `-7px`: `${fontHeight*0.6 + 7}px`
                        }}>{getOctaveInRussianNotation(note.octave)}</sup>}
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center"}}>
                            {transformFlatSign(note).note}
                            {transformFlatSign(note).isFlat && <sup css={{fontSize: fontHeight * 0.6}}>♭</sup>}
                            {settings.displayApplicature &&
                            <sup css={{fontSize: fontHeight * 0.6, color: "#D65F24"}}>{note.applicature}</sup>}
                        </div>
                    </div>
                </div>
                <NoteEditPopupMenu note={note}
                                   anchorEl={anchorEl}
                                   updateAnchorEl={(newVal) => setAnchorEl(newVal)}
                                   hand={hand}
                                   onUpdateNote={onUpdateNote}
                                   onClose={handlePopoverClose}
                                   nodeType={nodeType}
                                   lyrics={lyrics}
                />
            </div>
        </ClickAwayListener>)
})



const handAwareNoteByMidiComparator = (hand: HandType) => hand === HandType.RIGHT ?
    compareByMidiNumbers :
    (a, b) => -1 * compareByMidiNumbers(a, b)

// const getLevelDateForHand = ({left, right}, hand: HandType): LineInfo => {
//     return hand === HandType.RIGHT ? right : left
// }



export const NodeSubtitle = React.memo(({nodeData, setNotes, tripletProps, settings, lineNumber}: NodeSubtitleProps) => {
    const {displayLevelStorage} = useContext(BarContext)
    // const {settings} = useContext(SettingsContext)

    // console.log('rerendering subtitle', nodeData)

    const FONT_HEIGHT = settings.fontSize;
    const extraHeight = nodeData.hand === HandType.RIGHT ? 10 : FONT_HEIGHT;
    const MAX_HEIGHT = displayLevelStorage.getTotalNumberOfLevels(lineNumber, nodeData.hand) * FONT_HEIGHT*0.9 + extraHeight;


    const getNoteRelativeTop = (note: INote, isPartOfChord) => {

        // console.log('line number inside', lineNumber)
        const {index, midiOffset} = displayLevelStorage.getIndexAndOffsetForNote(note, lineNumber, nodeData.hand)
        // console.log('note', note.note + note.octave, 'level', index)
        return index * FONT_HEIGHT*0.9 + (isPartOfChord ? 0 :midiOffset* FONT_HEIGHT/20)
    }

    const getNoteHorizontalOffset = (note: INote) => {

        if (note.playbackOffset === PlaybackOffset.NONE && note.duration === PlaybackDuration.HALF) {
            return {right: getQuadratNodeDimension(settings.isMasteringMode).quadratWidth / 2 + 1}
        }
        if (note.playbackOffset === PlaybackOffset.HALF && note.duration === PlaybackDuration.HALF) {
            return {left: getQuadratNodeDimension(settings.isMasteringMode).quadratWidth / 2 + 1}
        }
        if (tripletProps) {
            const paddingOffset = 0.33;
            const effectiveProps = getTripletEffectiveParameters(tripletProps);
            const indexOfNoteInTriplet = effectiveProps.standardOffsets.indexOf(note.playbackOffset)

            const middleOffset = (effectiveProps.standardOffsets[2] - effectiveProps.standardOffsets[0]) / 2
            const offsetLeft = indexOfNoteInTriplet === 1 ?
                getQuadratNodeDimension(settings.isMasteringMode).quadratWidth * (middleOffset + paddingOffset) :
                getQuadratNodeDimension(settings.isMasteringMode).quadratWidth * (effectiveProps.standardOffsets[indexOfNoteInTriplet] + paddingOffset);
            return {left: offsetLeft}
        }

        return {left: 0, right: 0}
    }


    const handleUpdateOfNode = (oldNote: Note) => (newNote: Note, nodeType: NoteType, newLyrics?: string) => {
        const updatedNotes = [...nodeData.notes];
        const indexOfOldNote = updatedNotes.indexOf(oldNote);
        updatedNotes[indexOfOldNote] = newNote
        setNotes(updatedNotes, getOriginalText(updatedNotes, settings.octaveNotation), nodeType, newLyrics)

    }

    const constainsChords = isChord(nodeData.notes);
    const notesSortedByMidi = nodeData.notes.sort(handAwareNoteByMidiComparator(nodeData.hand))
    return (
        <div>
            <div>
                <div css={{
                    minHeight: MAX_HEIGHT,
                    marginTop:"5px",
                    width: getQuadratNodeDimension(settings.isMasteringMode).quadratWidth,
                    position: "relative",
                    textAlign: "center",
                }}>{
                    notesSortedByMidi
                        .map((note) => <NodeSubtitleItem
                                key={note.id}
                                note={note}
                                onUpdateNote={handleUpdateOfNode(note)}
                                hand={nodeData.hand}
                                height={getNoteRelativeTop(note, constainsChords)}
                                fontHeight={FONT_HEIGHT}
                                horizontalOffset={getNoteHorizontalOffset(note)}
                                nodeType={note.noteType}
                                lyrics={nodeData.lyrics}
                            />
                        )
                }
                </div>

            </div>
        </div>
    )
})


